<template>
    <div class="roadFront">
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="行政区划" v-if="roleName !== '干线公路'">
                                        <el-cascader :key="parseInt(Math.random()*10000)" style="width: 160px" size="medium" v-model="region" :options="regions" :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable ></el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 160px" size="medium" v-model="xmmc" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目法人单位">
                                        <el-input style="width: 160px" size="medium" v-model="frdw" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目类别">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedXmlb" :options="xmlbList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设规模">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedJsgm" :options="jsgmList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目性质">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="建设性质">
                                        <el-cascader size="medium" style="width: 160px" v-model="checkedJsxz" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item  label="分类">
                                        <el-cascader size="medium" style="width: 180px" v-model="checkedLabel" :options="labelList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: false }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item  label="前期进展">
                                        <el-cascader size="medium" style="width: 180px" v-model="checkedqq" :options="sgtsjList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清除</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="dudao" type="primary" size="mini" round icon="el-icon-thumb">督导台</el-button>
<!--                                    <el-button @click="exportData1" type="primary" size="mini" round icon="el-icon-upload2">前期进展统计导出</el-button>-->
                                    <el-button @click="exportData" type="primary" size="mini" round icon="el-icon-upload2">导出</el-button>
                                    <el-button @click="exportData2" type="primary" size="mini" round icon="el-icon-upload2">导出单个项目前期</el-button>
                                    <el-button v-if="isAdmin" @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">新增</el-button>
                                </span>
                                <h3 class="tit">公路列表  <span @click="qqtype = !qqtype">切换前期表</span></h3>
                            </div>
<!--                            <div class="box-content">-->
<!--                                <div class="tj">-->
<!--                                    <div class="total-item total-item-1">-->
<!--                                        <img src="../../assets/icon/tj1.png" alt="">-->
<!--                                        <div class="t">-->
<!--                                            <h5>项目数量<small>（个）</small></h5>-->
<!--                                            <b class="date-num">{{ xmsl }}</b>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                    <div class="total-item total-item-2">-->
<!--                                        <img src="../../assets/icon/tj2.png" alt="">-->
<!--                                        <div class="t">-->
<!--                                            <h5>建设规模-里程<small>（公里）</small></h5>-->
<!--                                            <b class="date-num">{{ jsgm }}</b>-->
<!--                                        </div>-->
<!--                                    </div>-->


<!--                                    <div class="total-item total-item-4">-->
<!--                                        <img src="../../assets/icon/tj4.png" alt="">-->
<!--                                        <div class="t">-->
<!--                                            <h5>总投资<small>（万元）</small></h5>-->
<!--                                            <b class="date-num">{{thousandBitSeparator(ztz.toFixed(0) )}}</b>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                </div>-->

<!--                                <el-tabs v-model="activeRoad" @tab-click="changeRoad">-->
<!--                                    <el-tab-pane label="全部" name="全部"></el-tab-pane>-->
<!--                                    <el-tab-pane label="高速公路" name="高速公路"></el-tab-pane>-->
<!--                                    <el-tab-pane label="普通国道" name="普通国道"></el-tab-pane>-->
<!--                                    <el-tab-pane label="普通省道" name="普通省道"></el-tab-pane>-->
<!--                                </el-tabs>-->
<!--                                <el-table  @sort-change="changeSort"  @cell-click="cellClick" ref="table" :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">-->
<!--                                    <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>-->
<!--&lt;!&ndash;                                    <el-table-column prop="SZDS"  label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <el-table-column prop="SZX" label="所属县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>&ndash;&gt;-->
<!--                                    <el-table-column prop="XMMC" label="项目名称" align="left" show-overflow-tooltip :width="320"></el-table-column>-->
<!--                                    <el-table-column prop="LXBH" label="路线编码" align="left" show-overflow-tooltip ></el-table-column>-->
<!--                                    <el-table-column prop="XMXZ" label="项目性质" sortable align="center" show-overflow-tooltip :width="150"></el-table-column>-->
<!--                                    <el-table-column prop="QQGZJZ" label="前期工作进展" :width="400" align="center" show-overflow-tooltip>-->
<!--                                        <el-table-column prop="JSGMGS" label="可行性研究" width="100" align="right">-->
<!--                                            <template slot-scope="scope">-->
<!--                                                <div :style="{color:scope.row.GKBLZT == 0 ? '' : scope.row.GKBLZT == '1' ? '#E6A23C' : '#67C23A'}">-->
<!--                                                    {{scope.row.GKBLZT == 0 ? '未开展' : scope.row.GKBLZT == '1' ? '办理中' : '已批复'}}-->
<!--                                                </div>-->
<!--                                            </template>-->
<!--                                        </el-table-column>-->
<!--                                        <el-table-column prop="JSGMGS" label="初步设计" width="90" align="right">-->
<!--                                            <template slot-scope="scope">-->
<!--                                                <div :style="{color:scope.row.CBSJBLZT == 0 ? '' : scope.row.CBSJBLZT == '1' ? '#E6A23C' : '#67C23A'}">-->
<!--                                                    {{scope.row.CBSJBLZT == 0 ? '未开展' : scope.row.CBSJBLZT == '1' ? '办理中' : '已批复'}}-->
<!--                                                </div>-->
<!--                                            </template>-->
<!--                                        </el-table-column>-->
<!--                                        <el-table-column prop="JSGMGS" label="施工图设计" width="100" align="right">-->
<!--                                            <template slot-scope="scope">-->
<!--                                                <div :style="{color:scope.row.SGTBLZT == 0 ? '' : scope.row.SGTBLZT == '1' ? '#E6A23C' : '#67C23A'}">-->
<!--                                                    {{scope.row.SGTBLZT == 0 ? '未开展' : scope.row.SGTBLZT == '1' ? '办理中' : '已批复'}}-->
<!--                                                </div>-->
<!--                                            </template>-->
<!--                                        </el-table-column>-->
<!--                                    </el-table-column>-->
<!--&lt;!&ndash;                                    <el-table-column prop="QQGZJZ" label="前期工作进展描述"  align="center" show-overflow-tooltip :width="150"></el-table-column>&ndash;&gt;-->
<!--                                    <el-table-column prop="ZTZ" label="计划总投资(万元)" :width="140" align="center" sortable show-overflow-tooltip>-->
<!--                                        <template slot-scope="scope">-->
<!--                                            <div style="text-align: right">-->
<!--                                                {{scope.row.ZTZ ? thousandBitSeparator(scope.row.ZTZ.toFixed(0) ): ''}}-->
<!--                                            </div>-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->
<!--&lt;!&ndash;                                    <el-table-column prop="SSWGHXMMC"  align="center" label="对应部“十四五”交通运输专项建设规划项目名称" show-overflow-tooltip :width="320" ></el-table-column>&ndash;&gt;-->

<!--&lt;!&ndash;                                    <el-table-column prop="JSXZ" label="建设性质"  align="center" show-overflow-tooltip :width="150"></el-table-column>&ndash;&gt;-->


<!--                                    <el-table-column label="建设规模" :width="150"  align="center">-->
<!--                                            <el-table-column prop="JSGMHJ" label="合计(公里)" width="90" align="right">-->
<!--                                                <template slot-scope="scope">-->
<!--                                                    {{scope.row.JSGMHJ ? scope.row.JSGMHJ.toFixed(3) : ''}}-->
<!--                                                </template>-->
<!--                                            </el-table-column>-->
<!--                                            <el-table-column prop="JSGMGS" label="高速(公里)" width="90" align="right">-->
<!--                                                <template slot-scope="scope">-->
<!--                                                    {{scope.row.JSGMGS ? scope.row.JSGMGS.toFixed(3) : ''}}-->
<!--                                                </template>-->
<!--                                            </el-table-column>-->
<!--                                            <el-table-column prop="JSGMYJ" label="一级(公里)" width="90" align="right">-->
<!--                                                <template slot-scope="scope">-->
<!--                                                    {{scope.row.JSGMYJ ? scope.row.JSGMYJ.toFixed(3) : ''}}-->
<!--                                                </template>-->
<!--                                            </el-table-column>-->
<!--                                        <el-table-column prop="JSGMEJ" label="二级(公里)" width="90" align="right">-->
<!--                                            <template slot-scope="scope">-->
<!--                                                {{scope.row.JSGMEJ ? scope.row.JSGMEJ.toFixed(3) : ''}}-->
<!--                                            </template>-->
<!--                                        </el-table-column>-->
<!--                                        <el-table-column prop="JSGMSJ" label="三级(公里)" width="90" align="right">-->
<!--                                            <template slot-scope="scope">-->
<!--                                                {{scope.row.JSGMSJ ? scope.row.JSGMSJ.toFixed(3) : ''}}-->
<!--                                            </template>-->
<!--                                        </el-table-column>-->
<!--&lt;!&ndash;                                            <el-table-column label="特大桥" width="50" align="center">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <el-table-column prop="JSGMTDQ" label="座数(座)" width="90" align="right"></el-table-column>&ndash;&gt;-->
<!--&lt;!&ndash;                                                <el-table-column prop="JSGMTDQYM" label="延米数(米)" width="90" align="right"></el-table-column>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </el-table-column>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <el-table-column label="隧道" width="50" align="center">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <el-table-column prop="JSGMSD" label="座数(座)" width="90" align="right"></el-table-column>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <el-table-column prop="JSGMSDYM" label="延米数(米)" width="90" align="right"></el-table-column>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </el-table-column>&ndash;&gt;-->
<!--                                    </el-table-column>-->
<!--                                    <el-table-column label="独立桥隧(延米)" align="center">-->
<!--                                        <el-table-column prop="JSGMTDQYM" label="桥梁" width="90" align="right">-->
<!--                                            <template slot-scope="scope">-->
<!--                                                {{scope.row.JSGMTDQYM ? scope.row.JSGMTDQYM.toFixed(2) : ''}}-->
<!--                                            </template>-->
<!--                                        </el-table-column>-->
<!--                                        <el-table-column prop="JSGMSDYM" label="隧道" width="90" align="right">-->
<!--                                            <template slot-scope="scope">-->
<!--                                                {{scope.row.JSGMSDYM ? scope.row.JSGMSDYM.toFixed(2) : ''}}-->
<!--                                            </template>-->
<!--                                        </el-table-column>-->

<!--                                    </el-table-column>-->
<!--                                    <el-table-column label="建设年限" align="center">-->
<!--                                        <el-table-column prop="KGN" label="开工年" :width="120" sortable show-overflow-tooltip></el-table-column>-->
<!--                                        <el-table-column prop="WGN" label="完工年" :width="120" sortable show-overflow-tooltip></el-table-column>-->
<!--                                    </el-table-column>-->
<!--                                    <el-table-column prop="XMFRDW" label="建设单位" align="center" show-overflow-tooltip :width="150" ></el-table-column>-->
<!--&lt;!&ndash;                                    <el-table-column prop="QQGZFZR" label="前期工作负责人" :width="150" align="center" show-overflow-tooltip></el-table-column>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <el-table-column prop="LXDH" label="联系电话" :width="150" align="center" show-overflow-tooltip></el-table-column>&ndash;&gt;-->
<!--                                    <el-table-column fixed="right" prop="" align="center" :width="195" label="操作">-->
<!--                                        <template slot-scope="scope">-->
<!--                                            <span class="newicon iconsyes" @click="showMaps(scope.row)"><i class="el-icon-location icons iconsyes"></i>定位</span>-->
<!--                                            <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>-->
<!--                                            <el-popconfirm v-if="!scope.row.IsLock" style="margin:0;display: inline-block;" confirm-button-text='确认' @confirm="changeLock(scope.row)" cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="是否锁定该项目？锁定后需管理员解锁?">-->
<!--                                                <template #reference>-->
<!--                                                    <div class="newicon iconsyes" ><i class="el-icon-lock icons iconsyes"></i>锁定</div>-->
<!--                                                </template>-->
<!--                                            </el-popconfirm>-->
<!--                                            <el-popconfirm v-else style="margin:0;display: inline-block;" confirm-button-text='确认' @confirm="changeLock(scope.row)" cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="是否解锁该项目?">-->
<!--                                                <template #reference>-->
<!--                                                    <div class="newicon iconsyes" ><i class="el-icon-unlock icons iconsyes"></i>解锁</div>-->
<!--                                                </template>-->
<!--                                            </el-popconfirm>-->
<!--                                        </template>-->
<!--                                    </el-table-column>-->

<!--                                </el-table>-->
<!--                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>-->
<!--                            </div>-->
                            <div class="box-content">
                                <div class="tj">
                                    <div class="total-item total-item-1">
                                        <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>已开展前期项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{ ykz }}
<!--                                                <span style="margin-left: 10px">{{((tjtotal/total)*100).toFixed(1)}}%</span>-->
                                            </b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-2">
                                        <img src="../../assets/icon/tj2.png" alt="">
                                        <div class="t">
                                            <h5>可研批复项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{ gksl}}</b>
                                        </div>
                                    </div>


                                    <div class="total-item total-item-3">
                                        <img src="../../assets/icon/tj4.png" alt="">
                                        <div class="t">
                                            <h5>初步设计批复项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{cssl}}</b>
                                        </div>
                                    </div>
                                    <div class="total-item total-item-4">
                                        <img src="../../assets/icon/tj4.png" alt="">
                                        <div class="t">
                                            <h5>施工图设计批复项目数量<small>（个）</small></h5>
                                            <b class="date-num">{{sgtsl}}</b>
                                        </div>
                                    </div>

                                </div>
                                <el-tabs v-model="activeRoad" @tab-click="changeRoad">
                                    <el-tab-pane label="全部" name="全部"></el-tab-pane>
                                    <el-tab-pane label="高速公路" name="高速公路"></el-tab-pane>
                                    <el-tab-pane label="普通国道" name="普通国道"></el-tab-pane>
                                    <el-tab-pane label="普通省道" name="普通省道"></el-tab-pane>
                                </el-tabs>
                                    <el-table v-show="qqtype" ref="multipleTable" :row-key="getRowKeys" @selection-change="handleSelectionChange" @sort-change="changeSort"  @cell-click="cellClick"  :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">
                                        <el-table-column
                                                :reserve-selection="true"
                                                type="selection"
                                                width="55">
                                        </el-table-column>
                                        <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                        <!--                                    <el-table-column prop="SZDS"  label="所在地市" :width="100"

                                     able show-overflow-tooltip></el-table-column>-->
                                        <!--                                    <el-table-column prop="SZX" label="所属县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>-->
                                        <el-table-column prop="XMMC" label="项目名称" align="left" show-overflow-tooltip :width="320"></el-table-column>
                                        <el-table-column prop="LXBH" label="路线编码" sortable align="left" show-overflow-tooltip ></el-table-column>
                                        <el-table-column prop="XMXZ" label="项目性质" sortable align="center" show-overflow-tooltip :width="150"></el-table-column>
                                        <el-table-column prop="QQGZJZ" label="前期工作进展" :width="400" align="center" show-overflow-tooltip>
                                            <el-table-column prop="JSGMGS" label="可行性研究" width="100" align="right">
                                                <template slot-scope="scope">
                                                    <div :style="{color:scope.row.GKBLZT == 0 ? '' : scope.row.GKBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                        {{scope.row.GKBLZT == 0 ? '未开展' : scope.row.GKBLZT == '1' ? '办理中' : '已批复'}}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="JSGMGS" label="初步设计" width="90" align="right">
                                                <template slot-scope="scope">
                                                    <div :style="{color:scope.row.CBSJBLZT == 0 ? '' : scope.row.CBSJBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                        {{scope.row.CBSJBLZT == 0 ? '未开展' : scope.row.CBSJBLZT == '1' ? '办理中' : '已批复'}}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="JSGMGS" label="施工图设计" width="100" align="right">
                                                <template slot-scope="scope">
                                                    <div :style="{color:scope.row.SGTBLZT == 0 ? '' : scope.row.SGTBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                        {{scope.row.SGTBLZT == 0 ? '未开展' : scope.row.SGTBLZT == '1' ? '办理中' : '已批复'}}
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table-column>
                                        <!--                                    <el-table-column prop="QQGZJZ" label="前期工作进展描述"  align="center" show-overflow-tooltip :width="150"></el-table-column>-->
                                        <el-table-column prop="ZTZ" label="计划总投资(万元)" :width="140" align="center" sortable show-overflow-tooltip>
                                            <template slot-scope="scope">
                                                <div style="text-align: right">
                                                    {{scope.row.ZTZ ? thousandBitSeparator(scope.row.ZTZ.toFixed(0) ): ''}}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <!--                                    <el-table-column prop="SSWGHXMMC"  align="center" label="对应部“十四五”交通运输专项建设规划项目名称" show-overflow-tooltip :width="320" ></el-table-column>-->

                                        <!--                                    <el-table-column prop="JSXZ" label="建设性质"  align="center" show-overflow-tooltip :width="150"></el-table-column>-->


                                        <el-table-column label="建设规模" :width="150"  align="center">
                                            <el-table-column prop="JSGMHJ" label="合计(公里)" width="90" align="right">
                                                <template slot-scope="scope">
                                                    {{scope.row.JSGMHJ ? scope.row.JSGMHJ.toFixed(3) : ''}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="JSGMGS" label="高速(公里)" width="90" align="right">
                                                <template slot-scope="scope">
                                                    {{scope.row.JSGMGS ? scope.row.JSGMGS.toFixed(3) : ''}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="JSGMYJ" label="一级(公里)" width="90" align="right">
                                                <template slot-scope="scope">
                                                    {{scope.row.JSGMYJ ? scope.row.JSGMYJ.toFixed(3) : ''}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="JSGMEJ" label="二级(公里)" width="90" align="right">
                                                <template slot-scope="scope">
                                                    {{scope.row.JSGMEJ ? scope.row.JSGMEJ.toFixed(3) : ''}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="JSGMSJ" label="三级(公里)" width="90" align="right">
                                                <template slot-scope="scope">
                                                    {{scope.row.JSGMSJ ? scope.row.JSGMSJ.toFixed(3) : ''}}
                                                </template>
                                            </el-table-column>
                                            <!--                                            <el-table-column label="特大桥" width="50" align="center">-->
                                            <!--                                                <el-table-column prop="JSGMTDQ" label="座数(座)" width="90" align="right"></el-table-column>-->
                                            <!--                                                <el-table-column prop="JSGMTDQYM" label="延米数(米)" width="90" align="right"></el-table-column>-->
                                            <!--                                            </el-table-column>-->
                                            <!--                                        <el-table-column label="隧道" width="50" align="center">-->
                                            <!--                                            <el-table-column prop="JSGMSD" label="座数(座)" width="90" align="right"></el-table-column>-->
                                            <!--                                            <el-table-column prop="JSGMSDYM" label="延米数(米)" width="90" align="right"></el-table-column>-->
                                            <!--                                        </el-table-column>-->
                                        </el-table-column>
                                        <el-table-column label="独立桥隧(延米)" align="center">
                                            <el-table-column prop="JSGMTDQYM" label="桥梁" width="90" align="right">
                                                <template slot-scope="scope">
                                                    {{scope.row.JSGMTDQYM ? scope.row.JSGMTDQYM.toFixed(2) : ''}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="JSGMSDYM" label="隧道" width="90" align="right">
                                                <template slot-scope="scope">
                                                    {{scope.row.JSGMSDYM ? scope.row.JSGMSDYM.toFixed(2) : ''}}
                                                </template>
                                            </el-table-column>

                                        </el-table-column>
                                        <el-table-column label="建设年限" align="center">
                                            <el-table-column prop="KGN" label="开工年" :width="120" sortable show-overflow-tooltip></el-table-column>
                                            <el-table-column prop="WGN" label="完工年" :width="120" sortable show-overflow-tooltip></el-table-column>
                                        </el-table-column>
                                        <el-table-column prop="XMFRDW" label="建设单位" align="center" show-overflow-tooltip :width="150" ></el-table-column>
                                        <!--                                    <el-table-column prop="QQGZFZR" label="前期工作负责人" :width="150" align="center" show-overflow-tooltip></el-table-column>-->
                                        <!--                                    <el-table-column prop="LXDH" label="联系电话" :width="150" align="center" show-overflow-tooltip></el-table-column>-->
                                        <el-table-column fixed="right" prop="" align="center" :width="195" label="操作">
                                            <template slot-scope="scope">
                                                <span class="newicon iconsyes" :class="{iconsyes1:!scope.row.isShowPosi}" @click="showMaps(scope.row)"><i class="el-icon-location icons"></i>定位</span>
                                                <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>详情</span>
                                                <el-popconfirm v-if="!scope.row.IsLock" style="margin:0;display: inline-block;" confirm-button-text='确认' @confirm="changeLock(scope.row)" cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="是否锁定该项目？锁定后需管理员解锁?">
                                                    <template #reference>
                                                        <div class="newicon iconsyes" ><i class="el-icon-lock icons iconsyes"></i>锁定</div>
                                                    </template>
                                                </el-popconfirm>
                                                <el-popconfirm v-else style="margin:0;display: inline-block;" confirm-button-text='确认' @confirm="changeLock(scope.row)" cancel-button-text='取消' icon="el-icon-info" icon-color="red" title="是否解锁该项目?">
                                                    <template #reference>
                                                        <div class="newicon iconsyes" ><i class="el-icon-unlock icons iconsyes"></i>解锁</div>
                                                    </template>
                                                </el-popconfirm>
                                            </template>
                                        </el-table-column>

                                    </el-table>
                                    <el-table  v-show="!qqtype" @sort-change="changeSort"  @cell-click="cellClick" ref="table" :data="tableData" size="small "  :height="tableHeight1" border  style="width: 100%">
                                        <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                        <el-table-column prop="XMMC" label="项目名称" align="center" show-overflow-tooltip :width="320">
                                            <template slot-scope="scope">
                                                <div style="text-align:left">
                                                   {{ scope.row.XMMC}}
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="index"  label="1.前期立项审批阶段"  align="center" >
                                            <el-table-column prop="index"  label="①可研阶段"  align="center" >
                                                <el-table-column prop="index"  label="工可招标"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="工可编制"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="工可审查（评审会）"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="前置要件"  align="center" >
                                                    <el-table-column prop="index"  label="规划选址"  align="center" >
                                                        <template slot-scope="scope">
                                                            <div :style="{color:scope.row.GHXZBLZT == 0 ? '' : scope.row.GHXZBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                                {{scope.row.GHXZBLZT == 0 ? '×' : scope.row.GHXZBLZT == '1' ? '⭕️' : '✅'}}
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column prop="index"  label="土地（用地）预审"  align="center" >
                                                        <el-table-column prop="index"  label="编制土地预审图件、文件"  align="center" ></el-table-column>
                                                        <el-table-column prop="index"  label="报自然资源厅审核及上报"  align="center" >

                                                            <template slot-scope="scope">
                                                                <div :style="{color:scope.row.YDYSBLZT == 0 ? '' : scope.row.YDYSBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                                    {{scope.row.YDYSBLZT == 0 ? '×' : scope.row.YDYSBLZT == '1' ? '⭕️' : '✅'}}
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column prop="index"  label="★自然资源部批复"  align="center" ></el-table-column>
                                                    </el-table-column>
                                                    <el-table-column prop="index"  label=" ★ 政府资金承诺"  align="center" >
                                                        <template slot-scope="scope">
                                                            <div :style="{color:scope.row.DFZJCNHBLZT == 0 ? '' : scope.row.DFZJCNHBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                                {{scope.row.DFZJCNHBLZT == 0 ? '×' : scope.row.DFZJCNHBLZT == '1' ? '⭕️' : '✅'}}
                                                            </div>
                                                        </template>

                                                    </el-table-column>
                                                    <el-table-column prop="index"  label=" 债务风险评估"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="★交通运输部出具意见"  align="center" ></el-table-column>
                                                </el-table-column>
                                                <el-table-column prop="index"  label="★审核及批复"  align="center" >
                                                    <template slot-scope="scope">
                                                        <div :style="{color:scope.row.GKBLZT == 0 ? '' : scope.row.GKBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                            {{scope.row.GKBLZT == 0 ? '×' : scope.row.GKBLZT == '1' ? '⭕️' : '✅'}}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table-column>
                                            <el-table-column prop="index"  label="②双业主制协议签订"  align="center" ></el-table-column>
                                            <el-table-column prop="index"  label="③初步设计阶段"  align="center" >
                                                <el-table-column prop="index"  label="初设招标"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="初设编制"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="初设审查（评审会）"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="初设审查（评审会）"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="专项批复(环评\水保\文物\地震\防洪\压覆\地灾\通航等)"  align="center" >
                                                    <template slot-scope="scope">
                                                        <div :style="{color:scope.row.HPBGPFBLZT == 0 ? '' : scope.row.HPBGPFBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                            {{scope.row.HPBGPFBLZT == 0 ? '×' : scope.row.HPBGPFBLZT == '1' ? '⭕️' : '✅'}}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column prop="index"  label="交通运输部部审查意见"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="★初设审核及批复"  align="center" >
                                                    <template slot-scope="scope">
                                                        <div :style="{color:scope.row.CBSJBLZT == 0 ? '' : scope.row.CBSJBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                            {{scope.row.CBSJBLZT == 0 ? '×' : scope.row.CBSJBLZT == '1' ? '⭕️' : '✅'}}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table-column>
                                            <el-table-column prop="index"  label="④施工图设计阶段"  align="center" >
                                                <el-table-column prop="index"  label="施工图招标"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="施工图编制"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="施工图审查（评审会）"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="★施工图审核及批复"  align="center" >
                                                    <template slot-scope="scope">
                                                        <div :style="{color:scope.row.SGTBLZT == 0 ? '' : scope.row.SGTBLZT == '1' ? '#E6A23C' : '#67C23A'}">
                                                            {{scope.row.SGTBLZT == 0 ? '×' : scope.row.SGTBLZT == '1' ? '⭕️' : '✅'}}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table-column>
                                            <el-table-column prop="index"  label="⑤两评一案批复阶段"  align="center" >
                                                <el-table-column prop="index"  label="两评批复"  align="center" >
                                                    <el-table-column prop="index"  label="咨询单位招标"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="两评报告编制"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="专家论证"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="★两评报告批复"  align="center" ></el-table-column>
                                                </el-table-column>
                                                <el-table-column prop="index"  label="实施方案批复"  align="center" >
                                                    <el-table-column prop="index"  label="实施方案编制"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="实施方案联审"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="★实施方案批复"  align="center" ></el-table-column>

                                                </el-table-column>

                                            </el-table-column>
                                        </el-table-column>


                                        <el-table-column prop="index"  label="2.招标（商）阶段"  align="center" >
                                            <el-table-column prop="index"  label="⑥资格预审阶段"  align="center" >
                                                <el-table-column prop="index"  label="资格预审、招标文件编制及评审"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="发布资格预审公告"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="资格预审申请文件审查）"  align="center" ></el-table-column>
                                            </el-table-column>
                                            <el-table-column prop="index"  label="⑦招投标阶段"  align="center" >
                                                <el-table-column prop="index"  label="招标文件编制及评审"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="发布招标公告"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="评标"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="★合同谈判，签订谈判备忘录"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="发中标通知书"  align="center" ></el-table-column>
                                            </el-table-column>
                                            <el-table-column prop="index"  label="⑧合同"  align="center" >
                                                <el-table-column prop="index"  label="批复投资协议、PPP项目合同"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="签订投资协议"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="成立项目公司"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="★签订PPP项目合同"  align="center" ></el-table-column>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column prop="index"  label="3.建设阶段"  align="center" >
                                            <el-table-column prop="index"  label="⑨开工准备阶段"  align="center" >
                                                <el-table-column prop="index"  label="林地（湿地、牧草）批复"  align="center" >
                                                    <el-table-column prop="index"  label="林地调查及组卷"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="省林业草原局批复"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="国家林业草原局批复"  align="center" ></el-table-column>
                                                </el-table-column>
                                                <el-table-column prop="index"  label="★先行用地组卷"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="★先行用地批复"  align="center" ></el-table-column>
                                                <el-table-column prop="index"  label="建设用地批复"  align="center" >
                                                    <el-table-column prop="index"  label="勘测定界成果编制"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="各市县自然资源局组卷"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="组卷上报省自然资源厅审核"  align="center" ></el-table-column>
                                                    <el-table-column prop="index"  label="★自然资源部批复"  align="center" ></el-table-column>
                                                </el-table-column>
                                                <el-table-column prop="index"  label="★质量监督手续办理及控制性工程施工许可"  align="center" ></el-table-column>
                                            </el-table-column>
                                        </el-table-column>
                                        <!--                                    <el-table-column prop="SZDS"  label="所在地市" :width="100"

                                     able show-overflow-tooltip></el-table-column>-->
                                        <!--                                    <el-table-column prop="SZX" label="所属县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>-->


                                    </el-table>
                                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
<!--            详情弹窗-->
            <el-drawer :visible.sync="showAddModel" @close="closeDrawer" size="1500px" :title="detail.xmmc"  class="editModal">
                <el-form :disabled="isLock"  :model="detail" :inline-message="true" ref="detailForm"  :inline="true" class="form-inline" v-loading="uploadLoading">
                    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                        <el-tab-pane label="基本信息" name="0">
                            <el-tabs tab-position="top"  :stretch="false">
                                <el-tab-pane label="基础信息">
                                    <div class="col">
                                        <div class="col-1">
                                            <el-form-item label='对应部规划项目'>
                                                <div class="dwmcWrap" v-if="!isLock">
                                                    <el-tooltip  :content="XMMC" placement="top" v-if="XMMC!= ''">
                                                        <div class="dwmc" @click="openTable">{{XMMC}}</div>
                                                    </el-tooltip>
                                                    <div v-else class="dwmc" @click="openTable">{{XMMC}}</div>
                                                    <div class="plus"><i @click="openTable" class="el-icon-circle-plus"></i></div>
                                                </div>
                                                <div  class="dwmcWrap" v-else>
                                                    <el-input style="width: 350px" v-model="XMMC"></el-input>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="项目名称" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                                <el-input placeholder="请输入项目名称" v-model="detail.xmmc"></el-input>
                                            </el-form-item>
                                            <el-form-item label="所在地市" prop="szds" :rules="[ { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)}, trigger: 'blur' }]">
                                                <el-input placeholder="请输入所在地市" v-model="detail.szds"></el-input>
                                            </el-form-item>
                                            <el-form-item label="所属县(区)" prop="szx" :rules="[
                                    { required: true, validator: (rule, value, callback)=>{checkNull(rule, value, callback)},trigger: 'blur' },
                                ]">
                                                <el-input placeholder="请输入所属县(区)" v-model="detail.szx"></el-input>
                                            </el-form-item>
                                            <el-form-item label="路线编号" prop="lxbh">
                                                <el-input placeholder="路线编号" v-model.number="detail.lxbh"></el-input>
                                            </el-form-item>
                                            <el-form-item  label="建设类别" >
                                                <el-select :disabled="!addStatus" placeholder="建设类别" v-model="detail.qqgllx">
                                                    <el-option label="高速公路" value="高速公路">高速公路</el-option>
                                                    <el-option label="普通国道" value="普通国道">普通国道</el-option>
                                                    <el-option label="普通省道" value="普通省道">普通省道</el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="项目性质" >
                                                <el-select  placeholder="项目性质" v-model="detail.xmxz">
                                                    <el-option label="续建项目" value="续建项目"></el-option>
                                                    <el-option label="新开工项目" value="新开工项目"></el-option>
                                                    <el-option label="谋划项目" value="谋划项目"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="建设性质" >
                                                <el-select multiple placeholder="建设性质" v-model="detail.jsxz">
                                                    <el-option label="新建" value="新建"></el-option>
                                                    <el-option label="升级改造" value="升级改造"></el-option>
                                                    <el-option label="原级改造" value="原级改造"></el-option>
                                                    <el-option label="路面改造" value="路面改造"></el-option>
                                                    <el-option label="改扩建" value="改扩建"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="开工年" >
                                                <el-date-picker
                                                        style="width: 330px"
                                                        format="yyyy"
                                                        value-format="yyyy-MM-dd"
                                                        v-model="detail.kgn" type="year" placeholder="选择日期">
                                                </el-date-picker>
                                            </el-form-item>
                                            <el-form-item label="完工年" >
                                                <el-date-picker  style="width: 330px"
                                                                 format="yyyy"
                                                                 value-format="yyyy-MM-dd"
                                                                 v-model="detail.wgn" type="year" placeholder="选择日期">
                                                </el-date-picker>
                                            </el-form-item>
                                            <el-form-item label="建设工期"  prop="jsgq" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input placeholder="建设工期" v-model.number="detail.jsgq"><template #suffix>年</template></el-input>
                                            </el-form-item>
                                            <el-form-item label="建设内容"  prop="jsnr" :rules="[{ required: true,message:'请选择建设内容',trigger: 'blur', }]">
                                                <el-select multiple placeholder="请选择建设内容" v-model="detail.jsnr">
                                                    <el-option label="高速公路" value="高速公路"></el-option>
                                                    <el-option label="一级公路" value="一级公路"></el-option>
                                                    <el-option label="二级公路" value="二级公路"></el-option>
                                                    <el-option label="三级公路" value="三级公路"></el-option>
                                                    <el-option label="路面改造" value="路面改造"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="总投资"  prop="ztz"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input placeholder="总投资" v-model.number="detail.ztz"><template #suffix>万元</template></el-input>
                                            </el-form-item>
                                            <el-form-item label="项目类别" >
                                                <el-select placeholder="请选择项目类别" v-model="detail.xmlb">
                                                    <el-option label="审批" value="审批"></el-option>
                                                    <el-option label="核准" value="核准"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="批准或核准机关" >
                                                <el-input placeholder="批准或核准机关" v-model="detail.pzhhzjg"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="col-1">
                                            <el-form-item label="行业主管部门" >
                                                <el-select :clearable="true" placeholder="行业主管部门" v-model="detail.hyzgbm">
                                                    <el-option label="省交通运输厅" value="省交通运输厅"></el-option>
                                                    <el-option label="省公路建设中心" value="省公路建设中心"></el-option>
                                                    <el-option label="省公路事业发展中心" value="省公路事业发展中心"></el-option>
                                                    <el-option label="省航务事业发展中心" value="省航务事业发展中心"></el-option>
                                                    <el-option label="省道路运输事业发展中心" value="省道路运输事业发展中心"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="建设模式" >
                                                <el-select :clearable="true" placeholder="建设模式" v-model="detail.jsms">
                                                    <el-option label="PPP" value="PPP"></el-option>
                                                    <el-option label="EPC" value="EPC"></el-option>
                                                    <el-option label="F+EPC" value="F+EPC"></el-option>
                                                    <el-option label="BOT" value="BOT"></el-option>
                                                    <el-option label="施工总包" value="施工总包"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <!--                                    <div class="deliver"></div>-->
<!--                                            <el-divider>建设单位信息</el-divider>-->
<!--                                            <el-form-item label="单位名称" prop="jsdw"  >-->
<!--                                                <el-input placeholder="单位名称" v-model="detail.jsdw"></el-input>-->
<!--                                            </el-form-item>-->
<!--                                            <el-form-item label="联系人" prop="jsdwlxr"  >-->
<!--                                                <el-input placeholder="联系人" v-model="detail.jsdwlxr"></el-input>-->
<!--                                            </el-form-item>-->
<!--                                            <el-form-item label="联系电话" prop="jsdwlxdh"  >-->
<!--                                                <el-input placeholder="联系电话" v-model="detail.jsdwlxdh"></el-input>-->
<!--                                            </el-form-item>-->
                                            <el-divider>建设单位信息</el-divider>
                                            <el-form-item label="单位名称" >
                                                <div class="dwmcWrap" style="margin-left: 0 " v-if="!isLock">
                                                    <div class="dwmc"   @click="showFr">{{XMFRDW}}</div>
                                                    <div class="plus"><i @click="showFr" class="el-icon-circle-plus"></i></div>
                                                </div>
                                                <div style="margin-left: 0 " v-else>
                                                    <el-input v-model="XMFRDW"></el-input>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="负责人" >
                                                <el-input placeholder="负责人" disabled v-model="confirmXMFR[0].XMZRR"></el-input>
                                            </el-form-item>
                                            <el-form-item label="联系电话" >
                                                <el-input placeholder="联系电话" disabled v-model="confirmXMFR[0].XMZRRLXDH"></el-input>
                                            </el-form-item>
                                            <el-divider>社会资本方信息</el-divider>
                                            <el-form-item label="单位名称" prop="shzbf">
                                                <el-input placeholder="单位名称" v-model="detail.shzbf"></el-input>
                                            </el-form-item>
                                            <el-form-item label="联系人" prop="shzbflxr"  >
                                                <el-input placeholder="联系人" v-model="detail.shzbflxr"></el-input>
                                            </el-form-item>
                                            <el-form-item label="联系电话" prop="shzbflxdh"  >
                                                <el-input placeholder="联系电话" v-model="detail.shzbflxdh"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="col-1">
                                            <el-form-item label="项目申请报告编制单位" prop="bzdw"  >
                                                <el-input placeholder="项目申请报告编制单位" v-model="detail.bzdw"></el-input>
                                            </el-form-item>
                                            <el-form-item label="工可编制单位" prop="gkbzdw"  >
                                                <el-input placeholder="工可编制单位" v-model="detail.gkbzdw"></el-input>
                                            </el-form-item>
                                            <el-form-item label="初设编制单位" prop="csbzdw"  >
                                                <el-input placeholder="初设编制单位" v-model="detail.csbzdw"></el-input>
                                            </el-form-item>
                                            <el-form-item label="施工图编制单位" prop="sgtbzdw"  >
                                                <el-input placeholder="施工图编制单位" v-model="detail.sgtbzdw"></el-input>
                                            </el-form-item>
                                            <el-form-item  label="前期工作进展">
                                                <el-input autosize style="width: 340px" placeholder="请输入前期工作进展" type="textarea" v-model="detail.qqgzjz"></el-input>
                                            </el-form-item>
                                            <el-form-item  label="项目基本情况">
                                                <el-input autosize style="width: 340px" placeholder="请输入项目基本情况" type="textarea" v-model="detail.jbqk"></el-input>
                                                <div style="color: #FB2828;font-size: 12px;line-height: 24px">（简要体现路线全长、技术标准、路面类型、造价、建设模式。示例：路线全长XX公里，技术标准为X级公路，设计速度XX公里/小时，路基宽XX米，XX路面，总投资XX万元，建设模式为传统模式）</div>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="旧路信息">
                                    <div class="col" style="justify-content: space-around ;height:500px">
                                        <div class="col-1" style="margin-right: 20px">
                                            <el-form-item label="改建年" >
                                                <el-date-picker
                                                        style="width: 330px"
                                                        format="yyyy"
                                                        value-format="yyyy-MM-dd"
                                                        v-model="detail.gjn" type="year" placeholder="选择日期">
                                                </el-date-picker>
                                            </el-form-item>
                                            <div style="color: #d6000f">最近一次改建年限</div>
                                            <el-form-item label="路线编号" prop="oldlxbh">
                                                <el-input placeholder="路线编号" v-model.number="detail.oldlxbh"></el-input>
                                            </el-form-item>
                                            <el-form-item  label="建设类别" >
                                                <el-select  placeholder="建设类别" v-model="detail.oldqqgllx">
                                                    <el-option label="前部" value="">全部</el-option>
                                                    <el-option label="高速公路" value="高速公路">高速公路</el-option>
                                                    <el-option label="普通国道" value="普通国道">普通国道</el-option>
                                                    <el-option label="普通省道" value="普通省道">普通省道</el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-divider>对应旧路起讫点</el-divider>
                                            <el-form-item label="起点名称" prop="qdmc">
                                                <el-input placeholder="起点名称" v-model.number="detail.qdmc"></el-input>
                                            </el-form-item>
                                            <el-form-item label="终点名称" prop="zdmc">
                                                <el-input placeholder="终点名称" v-model.number="detail.zdmc"></el-input>
                                            </el-form-item>

                                        </div>
                                        <div class="col-1">
                                            <el-form-item label="合计" :show-message="true" :inline-message="true" prop="xzhj"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input disabled placeholder="合计" v-model="detail.xzhj"></el-input>
                                            </el-form-item>
                                            <el-form-item label="高速"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input @input="changeXztotal($event,'gs')" placeholder="高速" v-model="detail.xzgs"></el-input>
                                            </el-form-item>
                                            <el-form-item label="一级"  prop="xzyj"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input @input="changeXztotal($event,'gs')" placeholder="一级" v-model="detail.xzyj"></el-input>
                                            </el-form-item>
                                            <el-form-item label="二级"  prop="xzej" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input @input="changeXztotal($event,'gs')" placeholder="二级" v-model="detail.xzej"></el-input>
                                            </el-form-item>
                                            <el-form-item label="三级"  prop="xzsj"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input @input="changeXztotal($event,'gs')" placeholder="三级" v-model="detail.xzsj"></el-input>
                                            </el-form-item>
                                            <el-form-item label="四级" prop="xzsi" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input @input="changeXztotal($event,'gs')" placeholder="四级" v-model="detail.xzsi"></el-input>
                                            </el-form-item>
                                            <el-form-item label="等外" prop="xzdw" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input @input="changeXztotal($event,'gs')" placeholder="等外" v-model="detail.xzdw"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="col-1" style="margin-left: 20px">
                                            <el-form-item label="特大桥" prop="xztdq" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input placeholder="特大桥" v-model="detail.xztdq"><template #suffix>座</template></el-input>
                                            </el-form-item>
                                            <el-form-item label="特大桥"  prop="xztdqym" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input @input="changeXztotal($event,'gs')" placeholder="特大桥" v-model="detail.xztdqym"><template #suffix>延米</template></el-input>
                                            </el-form-item>
                                            <el-form-item label="隧道"   prop="xzsd" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input placeholder="隧道" v-model="detail.xzsd"><template #suffix>座</template></el-input>
                                            </el-form-item>
                                            <el-form-item label="隧道"   prop="xzsdym" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input @input="changeXztotal($event,'gs')" placeholder="隧道" v-model="detail.xzsdym"><template #suffix>延米</template></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="建设规模">
                                        <div class="col" style="justify-content: flex-start;height:500px">
                                            <div class="col-1">
                                                <el-form-item label="合计"   prop="jsgmhj" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                    <el-input placeholder="合计" disabled v-model="detail.jsgmhj"></el-input>
                                                </el-form-item>
                                                <el-form-item label="高速"  prop="jsgmgs" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                    <el-input placeholder="高速" @input="changeJstotal($event,'gs')" v-model="detail.jsgmgs"></el-input>
                                                </el-form-item>
                                                <el-form-item label="一级"  prop="jsgmyj" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                    <el-input placeholder="一级" @input="changeJstotal($event,'gs')" v-model="detail.jsgmyj"></el-input>
                                                </el-form-item>
                                                <el-form-item label="二级"   prop="jsgmej" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                    <el-input placeholder="二级" @input="changeJstotal($event,'gs')" v-model="detail.jsgmej"></el-input>
                                                </el-form-item>
                                                <el-form-item label="三级"  prop="jsgmsj" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                    <el-input placeholder="三级" @input="changeJstotal($event,'gs')" v-model="detail.jsgmsj"></el-input>
                                                </el-form-item>
                                            </div>
                                            <div class="col-1"  style="margin-left: 20px">
                                                <el-form-item label="特大桥"  prop="jsgmtdq" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                    <el-input placeholder="特大桥" v-model="detail.jsgmtdq"><template #suffix>座</template></el-input>
                                                </el-form-item>
                                                <el-form-item label="特大桥"  prop="jsgmtdqym" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                    <el-input placeholder="特大桥" @input="changeJstotal($event,'gs')" v-model="detail.jsgmtdqym"><template #suffix>延米</template></el-input>
                                                </el-form-item>
                                                <el-form-item label="隧道"   prop="jsgmsd" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                    <el-input placeholder="隧道" v-model="detail.jsgmsd"><template #suffix>座</template></el-input>
                                                </el-form-item>
                                                <el-form-item label="隧道"   prop="jsgmsdym" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                    <el-input placeholder="隧道" @input="changeJstotal($event,'gs')" v-model="detail.jsgmsdym"><template #suffix>延米</template></el-input>
                                                </el-form-item>
                                                <el-form-item label="备注">
                                                    <el-input  style="width: 330px" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入备注" type="textarea" v-model="detail.bz"></el-input>
                                                </el-form-item>
                                            </div>
                                        </div>
                                </el-tab-pane>
                                <el-tab-pane :label="'项目建设占用新增永久用地面积'">
                                    <div class="col" style="height: 500px">
                                        <div class="col-1">
                                            <el-form-item label="农用地"  prop="ydnyd"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input placeholder="请输入农用地面积" @input="changeTotal($event,'nyd')" v-model="detail.ydnyd"><template #suffix>公顷</template></el-input>
                                            </el-form-item>

                                            <el-form-item label="其中: 耕地" prop="ydgd"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input placeholder="耕地" v-model="detail.ydgd"><template #suffix>公顷</template></el-input>
                                            </el-form-item>
                                            <el-form-item label="林地"  prop="ydld"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input placeholder="林地" v-model="detail.ydld"><template #suffix>公顷</template></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="col-1">
                                            <el-form-item label="基本农田"  prop="ydjbnt"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input placeholder="基本农田" v-model="detail.ydjbnt"><template #suffix>公顷</template></el-input>
                                            </el-form-item>
                                            <el-form-item label="未利用地" prop="wlyd"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input placeholder="未利用地" v-model="detail.ydwlyd"><template #suffix>公顷</template></el-input>
                                            </el-form-item>
                                        </div>
                                        <div class="col-1">
                                            <el-form-item label="合计"  prop="ydhj" :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">
                                                <el-input placeholder="合计" v-model="detail.ydhj"><template #suffix>公顷</template></el-input>
                                            </el-form-item>
                                        </div>
                                    </div>

                                </el-tab-pane>
                            </el-tabs>

                            <el-row>
                            </el-row>
<!--                            <el-row>-->

<!--                                <el-form-item label="主管单位" prop="zgdw" label-width="168px">-->
<!--                                    <el-input placeholder="请输入主管单位" v-model="detail.zgdw"></el-input>-->
<!--                                </el-form-item>-->
<!--                            </el-row>-->
<!--                            <el-row>-->
<!--                                <el-form-item label="执行机构" prop="zxjg" label-width="168px" >-->
<!--                                    <el-input placeholder="请输入执行机构" v-model="detail.zxjg"></el-input>-->
<!--                                </el-form-item>-->

<!--                            </el-row>-->
<!--                            <el-row>-->

<!--                            </el-row>-->
                        </el-tab-pane>
<!--                        <el-tab-pane label="旧路信息" name="1">-->

<!--                        </el-tab-pane>-->
<!--                        <el-tab-pane label="项目建设占用新增永久用地面积" name="3">-->
<!--                            <el-row>-->
<!--                                <el-row>-->


<!--                                </el-row>-->
<!--                                <el-row>-->

<!--                                </el-row>-->
<!--                                <el-row>-->

<!--                                </el-row>-->
<!--                                &lt;!&ndash;                                <el-row>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    <el-form-item label="未利用地" label-width="168px" prop="wlyd"  :rules="[{ required: true, validator: (rule, value, callback)=>{checkNum(rule, value, callback)},trigger: 'blur' }]">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                        <el-input placeholder="未利用地"  @input="changeTotal($event,'wlyd')" v-model="detail.ydwlyd"><template #suffix>公顷</template></el-input>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    </el-form-item>&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </el-row>&ndash;&gt;-->
<!--                                <el-row>-->

<!--                                </el-row>-->
<!--                            </el-row>-->

<!--                        </el-tab-pane>-->
                        <el-tab-pane label="前期流程" name="4" v-if="!addStatus">
                            <FlowTable :addStatus="addStatus" :key="11" :jbqk="detail.jbqk" :qqjzid="qqjzid" :isGg="gg" :isLock="isLock"></FlowTable>
                        </el-tab-pane>
                        <el-tab-pane label="附件" name="5" v-if="!addStatus">
                            <SLFJ v-on:cancel="cancel()" :XMID="qqjzid" :isLock="isLock" :key="parseInt(Math.random()*10000)" :SSWSSWGHXMID="SSWSSWGHXMID"></SLFJ>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="footer"  v-if="activeName != '4' && activeName != '5'">
                        <el-button type="primary" @click="save" :loading="saveLoading">保存</el-button>
                        <el-button @click="cancel">取消</el-button>
                        <!--                    <el-button  @click="changeLock()">锁定</el-button>-->
                    </div>
                </el-form>
            </el-drawer>
<!--            建设单位列表弹窗-->
            <el-drawer :visible.sync="frCompany" :title="'建设单位列表'" size="1200px">
                <el-form :inline="true" :model="frForm" class="demo-form-inline" style="margin-top: 42px">
                  <div class="search">
                      <el-form-item label="" prop="dwmc"  :rules="[
                                    { required: false, message:'请输入单位名称', trigger: 'blur' },
                                ]">
                          <el-input v-model="frForm.dwmc"  placeholder="请输入单位名称"></el-input>
                      </el-form-item>
                      <el-form-item label="">
                          <el-input v-model="frForm.xmzrr" placeholder="请输入项目负责人"></el-input>
                      </el-form-item>
                      <el-form-item label="">
                          <el-input v-model="frForm.xmlxr" placeholder="请输入项目联系人"></el-input>
                      </el-form-item>
                      <el-form-item>
                          <el-button type="primary" @click="getFRDW">查询</el-button>
                      </el-form-item>
                      <el-form-item>
                          <el-button type="primary" @click="conformXmlxr">确定</el-button>
                      </el-form-item>
                      <el-form-item>
                          <el-button type="primary" @click="addCompany">新增</el-button>
                      </el-form-item>
                  </div>
                </el-form>
                <template>
                    <el-table
                            :row-class-name="tableRowClassName"
                            max-height="550"
                            border
                            ref="multipleTable1"
                            :data="frCompanyList"
                            style="width: 100%"
                            @selection-change="selectCompany1"
                    >
                        <el-table-column type="selection" width="55"  :selectable="checkSelectable1"/>
                        <el-table-column  :filter-multiple="false"
                                          fixed prop="DWMC" label="单位名称"  show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMZRR" label="项目负责人"  show-overflow-tooltip></el-table-column>
                        <el-table-column fixed width="150px" prop="XMZRRLXDH" label="负责人联系电话"  show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMLXR" label="项目联系人"   show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMLXRLXDH" label="联系人电话"  show-overflow-tooltip></el-table-column>
                        <el-table-column fixed label="操作"  show-overflow-tooltip >
                            <template slot-scope="scope">
                                <el-button type="text"  size="small" @click="edit(scope.row)">编辑</el-button>
                                <el-popconfirm  title="是否删除此条信息?"  @confirm="deletedCompany(scope.row)">
                                    <template #reference >
                                        <el-button style="margin-left: 12px" type="text" size="small" color="#d6000f">删除</el-button>
                                    </template>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--                <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>-->
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>
<!--            十四五项目弹窗-->
            <el-drawer :visible.sync="assign" :title="detail.XMMC" size="80%">
                <el-form :inline="true" :model="formInline" class="demo-form-inline" style="margin-top: 42px;display:flex">
                   <div class="search">
                       <el-form-item label="项目名称">
                           <el-input style="width: 300px" v-model="formInline.XMMC" placeholder="请输入项目名称"></el-input>
                       </el-form-item>
                   </div>
                    <el-form-item>
                        <el-button type="primary" @click="searchCompanyList">搜索</el-button>
                        <el-button type="primary" @click="confirmCompany">确定</el-button>
                    </el-form-item>
                </el-form>
                <template>
                    <el-table :header-cell-class-name="cellClass" @selection-change="selectCompany"  ref="multipleTable" :data="companyData"  border :default-sort="{ prop: 'SSDS', order: 'ascending' }" style="width: 100%">
                        <el-table-column fixed type="selection" width="55"  :selectable="checkSelectable"/>
                        <el-table-column fixed prop="SSDS" label="所在地市" :width="100" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="SSX" label="所属县（市）" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column fixed prop="XMMC" label="项目名称" show-overflow-tooltip :width="320" sortable></el-table-column>
                        <el-table-column prop="LXBH" label="路线编号" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="XMXZ" label="项目性质" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSXZ" label="建设性质" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="XMLX" label="项目类型" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="GLLX" label="建设类别" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="KGN" label="开工年" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="WGN" label="完工年" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSGMGS" label="建设规模高速（公里）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSGMYJ" label="建设规模一级（公里）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSGMEJ" label="建设规模二级（公里）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSGMSJ" label="建设规模三级（公里）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="JSGMQS" label="建设规模桥隧（数量）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="ZTZ" label="总投资" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="ZYCGS" label="中央车购税" :width="150" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SAWZTJ" label="“十三五”投资（亿元）" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SAWZYCGS" label="“十三五”中央车购税（亿元）" :width="210" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SSWZTZ" label="“十四五”投资" :width="160" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SSWZYCGS" label="“十四五”中央车购税" :width="210" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SSWSJZJ" label="“十四五”省级资金" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SSWSXZCTZ" label="“十四五”市县征拆投资" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SWWZTZ" label="“十五五”投资" :width="160" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SWWZYCGS" label="“十五五”中央车购税" :width="170" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="SFYDSXW" label="是否地市行文" :width="160" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="XMLY" label="项目来源" :width="160" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="QQGZJZ" label="前期工作进展" :width="160" sortable show-overflow-tooltip></el-table-column>
                        <el-table-column prop="BZ" label="备注" show-overflow-tooltip></el-table-column>
                    </el-table>
                    <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[10, 20, 30,40]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>
                    <!--                <el-pagination background @size-change="handleSizeChange1" @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="total1"></el-pagination>-->
                </template>
                <template #footer>
      <span class="dialog-footer">
        <el-button @click="assign = false">取消</el-button>
      </span>
                </template>
            </el-drawer>
        </div>
        <el-dialog
                :visible.sync="showMessage"
                title="提示"
                width="30%"
        >
            <span>当前页面未保存,是否确定退出?</span>
            <template #footer>
      <span class="dialog-footer">
        <el-button @click="showMessage = false">取消</el-button>
        <el-button type="primary" @click="closeEdit">确认</el-button>
      </span>
            </template>
        </el-dialog>

<!--建设单位编辑弹窗-->
        <el-drawer :visible.sync="showEdit" :title="title" size="450px" :inline="true">
            <template>
                <el-form  ref="editForm" label-width="178px" :model="editModel"  :inline="true" class="form-inline showEdit" >
                    <el-form-item style="margin-top: 20px"  label="建设单位名称" prop="DWMC"  :rules="[
                                    { required: true, validator: (rule, value, callback)=>{checkEdit(rule, value, callback)}, trigger: 'blur' },
                                ]">
                        <el-input style="width: 200px" v-model="editModel.DWMC" :disabled="!newAdd" placeholder="请输入单位名称" ></el-input>
                    </el-form-item>
                    <el-form-item style="margin-top: 20px" label="项目负责人" prop="xmzrr">
                        <el-input style="width: 200px" v-model="editModel.XMZRR" placeholder="请输入项目负责人"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-top: 20px" label="负责人联系电话" prop="xmzrrlxdh">
                        <el-input style="width: 200px" v-model="editModel.XMZRRLXDH" placeholder="请输入负责人电话"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-top: 20px" label="项目联系人" prop="xmlxr">
                        <el-input style="width: 200px" v-model="editModel.XMLXR" placeholder="请输入项目联系人"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-top: 20px" label="联系人电话" prop="xmlxrlxdh">
                        <el-input style="width: 200px"  v-model="editModel.XMLXRLXDH" placeholder="请输入联系人电话"></el-input>
                    </el-form-item>
                </el-form>
            </template>
            <span class="dialog-footer" style="justify-content: flex-end;display: flex;align-items:center;margin-top: 24px">
                    <el-button type="primary" @click="saveCompany">保存</el-button>
                    <el-button  @click="cancel()">取消</el-button>
                  </span>
        </el-drawer>
        <el-dialog :title="dialogTableVisibleData.XMMC" :visible.sync="dialogTableVisibleMap" width="100%" class="mapdialog mapdialog1">
            <PlanForm :key="parseInt(Math.random()*10000)" v-if="dialogTableVisibleMap" :isLock="isLock" :dialogData="dialogTableVisibleData" />
        </el-dialog>


        <el-drawer :visible.sync="showWarning" title="督导台" size="600px" :inline="true">
            <template>
                <el-button @click="exportData3" style="margin: 10px 10px 0" type="primary" size="mini" icon="el-icon-upload2">导出</el-button>
                <el-collapse v-model="activeNames" @change="handleChange">
                    <el-collapse-item  v-for="(item,index) in warningData" :title="item.frdw" :key="index" :name="index">
                        <el-descriptions :title="item.xmmc">
                        </el-descriptions>
                           <el-table
                                   :data="item.cqjd"
                                   style="width: 100%"
                                   :row-class-name="warningClassName">
                               <el-table-column prop="jdmc" label="节点名称" ></el-table-column>
                               <el-table-column prop="jywcsj" label="计划完成时间" ></el-table-column>
                               <el-table-column prop="jyzq" label="建议周期" align="center"></el-table-column>
                               <el-table-column prop="cqsj" label="超期时长" align="center">
<!--                                   <template  slot-scope="scope">-->
<!--                                       <div v-if="scope.row.cqsj == 0" style="color: #ffffff;background: #E6A23C;">{{scope.row.cqsj}}</div>-->
<!--                                       <div v-else style="color: #ffffff;background:#F56C6C">{{scope.row.cqsj}}</div>-->
<!--                                   </template>-->
                               </el-table-column>
                           </el-table>

                    </el-collapse-item>
                </el-collapse>
            </template>
        </el-drawer>

       <div class="meng" v-if="showWarningDialog">
            <div class="cardRed">
                <div class="cardW">
                    <div class="yjicon">
                        <img src="../../assets/images/yujing2.png" alt="">
                    </div>
                    <div class="cardCont" v-if="!isAdmin">
                        <div>您有<span class="cq">【{{cqsl}}】</span>个项目前期节点已超期</div>
                        <div>您有<span class="lq">【{{lqsl}}】</span>个项目前期节点已临近计划完成时间</div>
                        <div>请尽快处理!</div>
                    </div>
                    <div class="cardCont" v-else>
                        <div><span class="cq" v-for="(item,index) in yjdw" :key="index">【{{item}}】</span></div>
                        <div>以上单位共有<span class="lq">【{{lqsl}}】</span>个项目前期节点已临近计划完成时间
                            <span class="lq">【{{cqsl}}】</span>个项目超期
                        </div>
                        <div>请尽快处理!</div>
                    </div>
                    <div class="cardButton" @click="dudao">查看详细内容</div>

<!--                    <div v-for="(item,index) in warningData" :key="index">-->
<!--                        <div>{{item.xmmc}}</div>-->
<!--                        <div v-for="(subItem,subIndex) in item.cqjd" :key="subIndex">-->
<!--                            <span class="jdmc">【{{subItem.jdmc}}】</span>-->
<!--                            <span class="cq" v-if="subItem.cqsj != 0">已超期{{subItem.cqsj}}天</span>-->
<!--                            <span class="lq" v-else>已进入临界范围</span>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>
           <div class="close" @click="showWarningDialog = false"><img src="../../assets/images/close.png" alt=""></div>
       </div>
    </div>
</template>
<script>
    import PlanForm from "../frontMap/PlanForm";
    import { exportData } from "../../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../../store/index'
    import http from "../../api/http";
    import FlowTable from "../../components/Query/Road/FlowTable2";
    import SLFJ from "../../components/Query/Road/SLFJ";
    import JSZip from 'jszip'
    import FileSaver from "file-saver";
    export default {
        name: "Home",
        components: {FlowTable,SLFJ,PlanForm},
        // components: {FlowTable},
        data() {
            return {
                noLine:['国道三合至莫旗公路杏山至老松岭段','北京至黑瞎子岛公路哈尔滨四环路至呼兰段','G221国道同江至哈尔滨公路友谊至集贤段','S516省道逊克至孙吴公路逊克口岸至逊克（靠山村）段','S203省道同江至汪清公路集贤镇至福利镇段',
                '国道丹阿公路呼玛至十八站段改扩建工程','G331国道丹东至阿勒泰公路穆棱（八面通镇）至黑山村（鸡穆界）段','G501国道集贤至当壁公路宝山区至宝清段','G331国道丹东至阿勒泰公路黑山村（鸡穆界）至鸡西段','国道鹤大公路复兴桥至汤原界',
                'G221国道同江至哈尔滨公路佳木斯依兰界至依兰方正界段','省道连崟至兴华公路新林至兴华段','S102省道哈尔滨至亚布力公路尚志镇至一面坡段','哈同高速集贤至同江段改线','国道北京至抚远公路北向阳至吉城外环段新建工程','国道绥芬河至满洲里公路尚志阿城界至平山镇段',
                '萝额公路鹤岗至梧桐河段大修工程','国道三莫公路雪乡至滑雪场段（黑龙江段）','国道三莫公路雪乡至滑雪场段（吉林段）','国道三莫公路滑雪场至亚布力段','哈尔滨至通河高速（按G102京抚公路哈尔滨至通河改扩建一级））','通河至佳木斯高速（按G102京抚公路通河至佳木斯改扩建一级路）',
                '国道京抚公路通依界至依汤界段路面改造工程','国道鹤岗至大连公路勃利鸡东界至鸡东鸡西界段改扩建工程','醉美龙江331边防路二期项目','省道阿城至扶余公路拉林河大桥','省道哈尔滨至大安公路世贸大道至哈肇界段',
                '省道S309建兴至新林公路海伦过境段','省道S101哈尔滨至北安公路长发至海北界段路面改造工程','绥化机场路项目','绥满高速哈尔滨至大庆段（谋划）','国道集贤至当壁公路连珠山至兴凯段',
                '省道名山至兴凯湖公路291一分场19队入点至291一分场30队出点','绥满高速公路哈尔滨至阿城段（谋划）','北漠高速嫩江至加格达奇段（谋划）','202国道榆林段绕城环路项目','国道嘉荫至临江公路南岔至铁力段','省道东安镇至同江公路三村至同江段',
                '国道绥化至沈阳公路安达至肇源段','国道集贤至当壁公路宝清至密山（兴凯互通）段','国道饶河农场至乌苏镇公路','国道绥化至沈阳公路望奎过境段','国道呼玛（金山乡）至漠河口岸公路',
                '国道绥化至沈阳公路安达至肇源段','国道饶河农场至乌苏镇公路(饶河县)','宝清支线机场连接线','逊克通用机场连接线（S516逊克县至逊河镇段）','鸡西东南环新建项目'],
                yjdw:[],
                showWarningDialog: false,
                cqsl:0,
                lqsl:0,
                warningToast: true,
                warningData:[],
                activeNames: [],
                showWarning: false,
                qqtype: true,
                tjtotal:0,
                pieTotal:0,
                ykz:0,
                gksl:0,
                cssl:0,
                sgtsl:0,
                saveLoading: false,
                labelList:[ {
                    value: "全部",
                    label: "全部",
                    disabled: false,
                },
                    {
                        value: "重点项目",
                        label: "重点项目",
                        disabled: false,
                    },],
                checkedqq:[],
                checkedLabel: [], //标签分类
                xmsl:'0',
                jsgm:'0',
                ztz:0,
                sswtz:0,
                activeRoad: '全部',
                isLock: false, //fasle 未锁定  true 已锁定
                dialogTableVisibleMap:false,
                dialogTableVisibleData:{},
                roleName:'',
                activeName:'0',
                required: false,
                innerDrawer: false,
                title:'新增建设单位',
                ruleForm:{},
                chekfr:[{XMZRR:'',XMZRRLXDH:''}],
                confirmXMFR:[{XMZRR:'',XMZRRLXDH:''}],
                XMFRDW:'',
                XMFRDWID:'',
                frCompany:false,
                frCompanyList:[],
                frdw:'',
                frForm:{},
                formInline:{},
                assign: false,
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                detail:{
                    jsxmydys:{pfwj:[]},
                    ptzjcns:{pfwj:[]},
                    stbcpjpf:{pfwj:[]},
                    ghxzyjs:{pfwj:[]},
                    hjyxpjpf:{pfwj:[]},
                    jnpg:{pfwj:[]},
                    shwd:{pfwj:[]},
                    xmjy:{pfwj:[]},
                    gky:{pfwj:[]},
                    cbsj:{pfwj:[]},
                    sgt:{pfwj:[]},
                    zsyd:{pfwj:[]},
                    sgxk:{pfwj:[]},
                    jtb:{pfwj:[]},
                    qt:{pfwj:[]}
                },
                showDetail: false,
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                gkbz:false,
                singleData:{
                    wlyd:0,
                    nyd:0
                },
                XMMC:'',
                addStatus: true,
                Collapse:['1','2','3'],
                step:['一、工可','二、初步设计','三、施工图设计','四、施工前准备','五、县域国土空间规划'],
                activeProcess:0,
                showEditModel: false,
                confirmSSW:[],
                tableHeight:0, //表格高度
                tableHeight1:0, //表格高度
                activeIndex: true, //显隐高级搜索
                /**查询条件 begin*/
                xmmc: "", //项目名称
                xmnd: "", //项目年度
                gllx: "", //建设类别
                checkedXmxz: [], //项目性质
                checkedJsxz: [], //建设性质
                checkedXmlx: [], //项目类型
                checkedXmlb: [], //项目类别
                checkedJsgm: [], //项目类别
                checkedGk: [], //项目类别
                checkedSgtsj: [], //项目类别
                checkedCbsj: [], //项目类别
                region: [""], //已选中行政区划
                qqgzjz: [], //前期工作进展
                /**查询条件 end*/
                xmxzShow: true, //项目性质查询条件显隐
                jsxzShow: true, //建设性质查询条件显隐
                xmlxShow: true, //项目类型查询条件显隐
                checkList: [],
                checkAllXmlb: false,
                checkAllXmxz: false,
                checkAllJsxz: false,
                checkAllXmlx: false,
                checkAllJsgm: false,
                checkAllGk: false,
                checkAllCbsj: false,
                checkAllSgtsj: false,
                xmxzList: [
                    {
                        value: "续建项目",
                        label: "续建项目",
                        disabled: false,
                    },
                    {
                        value: "新开工项目",
                        label: "新开工项目",
                        disabled: false,
                    },
                    {
                        value: "谋划项目",
                        label: "谋划项目",
                        disabled: false,
                    }
                ],
                jsxzList: [
                    { value: "新建", label: "新建", disabled: false },
                    { value: "升级改造", label: "升级改造", disabled: false },
                    { value: "原级改造", label: "原级改造", disabled: false },
                    { value: "路面改造", label: "路面改造", disabled: false },
                    { value: "改扩建", label: "改扩建", disabled: false },
                ],
                xmlxList: ["正选项目", "备选项目"],
                jsgmList: [{value: "JSGMGS", label: "高速", disabled: false},{value: "JSGMYJ", label: "一级", disabled: false},{value: "JSGMEJ", label: "二级", disabled: false},{value: "JSGMSJ", label: "三级", disabled: false},{value: "JSGMTDQ", label: "特大桥", disabled: false},{value: "JSGMSD", label: "隧道", disabled: false}],
                xmlbList: [{value: "审批", label: "审批", disabled: false},{value: "核准", label: "核准", disabled: false}],
                // gkList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                // cbsjList: [{value: "0", label: "未开展", disabled: false},{value: "1", label: "办理中", disabled: false},{value: "2", label: "已办结", disabled: false}],
                sgtsjList: [{value: "0", label: "可行性研究", disabled: false},{value: "1", label: "初步设计", disabled: false},{value: "2", label: "施工图设计", disabled: false}],
                currentPage: 1, //当前页
                currentPage1: 1, //当前页
                pageSize: 30, //每页记录数
                pageSize1: 10, //每页记录数
                isIndeterminateXmxz: true,
                isIndeterminateJsgm: true,
                isIndeterminateGk: true,
                isIndeterminateCbsj: true,
                isIndeterminateSgtsj: true,
                isIndeterminateJsxz: true,
                isIndeterminateXmlx: true,
                isIndeterminateXmlb: true,
                SSWSSWGHXMID:'',
                regions: [],
                tableData: [], //请求列表
                total: 0, //请求记录数
                total1: 0, //请求记录数
                totalSqu:0,
                companyData:[],
                searchAll:[],
                chek:[],
                newAdd:false,
                editModel:{},
                showEdit:false,
                qqjzid:'',
                innerDrawerTitle:'',
                gg: '',
                GKWCD:'',
                CBSJWCD:'',
                SGTSJWCD:'',
                SGQZBWCD:'',
                isAdmin: false,
                rowData:{},
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
                saveTag:[],
                loadList:[],
                selectPro:[],
            };
        },
        mounted() {
            var that = this
            // this.getTagList()
            this.http.post('/api/Plan_high_national_early_node/GetEarlyWarningInfo',{page:1,rows:1000,}).then(res=>{
                this.warningData = res.data
                var a = 0
                var b = 0
                var c = []
                var d  =[]
                res.data.map((item,index)=>{
                    c.push(item.frdw)
                    item.cqjd.map((subitem,subIndex)=>{
                        if (subitem.cqsj == 0){
                            a++
                        }else{
                            b++
                        }
                    })
                    this.activeNames.push(index)
                })
                c = new Set(c)
                d = new Set(d)
                console.log(c)
                this.cqsl = b
                this.lqsl = a
                this.yjdw = c
                if(this.cqsl != 0 || this.lqsl != 0){
                    this.showWarningDialog = true
                }
            })
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height - 255;
                that.tableHeight1 = document.body.offsetHeight - height;
            }, 100);
            this.getRegion();
            this.search();
            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.roleName = res.data.roleName
                this.isAdmin = res.data.roleName == '超级管理员' || res.data.userTrueName== '黑龙江省公路事业发展中心'|| res.data.isMain  == 1 ? true : false
            })

            this.getCurrent()

            // this.getCompanyList()
            // this.getFRDW()
        },
        computed:{

        },
        methods: {

            getRowKeys(row){
                return row.ID;
            },
            handleSelectionChange(val) {
                this.selectPro = []
                console.log(val)
                val.map((item,index)=>{
                    this.selectPro.push(item.ID)

                })
            },
            dudao(){
                this.showWarningDialog = false
                this.GetEarlyWarningInfo()
                this.showWarning = true
            },
            GetEarlyWarningInfo(){
                var that = this
                this.http.post('/api/Plan_high_national_early_node/GetEarlyWarningInfo',{page:1,rows:1000,}).then(res=>{
                    this.warningData = res.data
                    var a = 0
                    var b = 0
                    res.data.map((item,index)=>{
                        item.cqjd.map((subitem,subIndex)=>{
                            if (subitem.cqsj == 0){
                                a++
                            }else{
                                b++
                            }
                        })
                        this.activeNames.push(index)
                    })
                    this.cqsl = b
                    this.lqsl = a
                })
            },
            getTagList(){
                var that = this
                this.http.post('/api/Land_road_tag_base/GetPageData',{page:1,rows:1000,}).then(res=>{
                    console.log(res)
                    this.labelList = res.rows
                    this.labelList.map((item,index)=>{
                        item.label = item.BQWZ
                        item.value = item.ID
                        item.disabled = false
                    })
                    that.$nextTick(() => {
                        res.rows.forEach((item, index) => {
                            // if (!item.canChoose) {
                            //     // 默认被选中且禁用
                            //     this.$refs.multipleTable.toggleRowSelection(item, true);  // 如果只需要禁用 不需要这行
                            // }
                            //  selectReady 是默认要选中的数据
                            if (that.saveTag.length > 0) {
                                that.saveTag.forEach((obj, key) => {
                                    if (item.ID == obj.TagId) { // 如果匹配上  代表当前角色下的用户  高亮选中
                                        console.log('22222222222')
                                        that.$refs.multipleTables.toggleRowSelection(item);
                                    }
                                })
                            }
                        })
                        // this.chek = rows.TagList

                    })

                })
            },
            cellClick(row, column, cell, event){
                if (column.label != '操作'){
                    this.openDetailsEdit(row)
                }
            },
            getPageTotal() {
                this.http
                    .post("/api/Plan_high_national_early/GetPageTotal", this.postData())
                    .then((res) => {
                        this.xmsl = res.xmsl;
                        this.jsgm = res.jsgm;
                        this.jsqmqs = res.jsqmqs;
                        this.ztz = res.ztz;
                        this.sswtz = res.sswtz;
                        this.ykz = res.ykz;
                        this.gksl = res.gksl;
                        this.cssl = res.cssl;
                        this.sgtsl = res.sgtsl;
                    });
            },
            changeJslb(val){
                console.log(val)
            },
            changeRoad(tab, event){
                this.currentPage = 1
                if(tab.label == '全部'){
                    this.gllx = ''
                }else{
                    this.gllx = tab.label
                }
                this.search()
                switch (tab.label) {
                    case '全部':
                        this.tjtotal = this.pieTotal
                        this.ky = this.piegs.GK + this.piegd.GK + this.piesd.GK
                        this.cs = this.piegs.CBSJ + this.piegd.CBSJ + this.piesd.CBSJ
                        this.ss = this.piegs.SGT + this.piegd.SGT + this.piesd.SGT
                        break;
                    case '高速公路':
                        this.tjtotal = this.piegs.Total-this.piegs.GKWKZ
                        this.ky = this.piegs.GK
                        this.cs = this.piegs.CBSJ
                        this.ss = this.piegs.SGT
                        break;
                    case '普通国道':
                        this.tjtotal = this.piegd.Total-this.piegd.GKWKZ
                        this.ky = this.piegd.GK
                        this.cs = this.piegd.CBSJ
                        this.ss = this.piegd.SGT
                        break;
                    case '普通省道':
                        this.tjtotal = this.piesd.Total-this.piesd.GKWKZ
                        this.ky = this.piesd.GK
                        this.cs = this.piesd.CBSJ
                        this.ss = this.piesd.SGT
                        break;
                }
            },
            changeLock(row){
                this.isLock = row.IsLock
                if(this.isLock && this.isAdmin){
                    this.isLock = false
                }else if(!this.isLock ){
                    this.isLock = true
                }else if(this.isLock && !this.isAdmin){
                    this.$message.warning('请联系管理员进行解锁')
                    return false
                }
                row.IsLock = this.isLock
                    this.http.post('/api/Plan_high_national_early/UpdatePlanHighNationalEarly',row).then(res=>{
                        if(res.status){
                            this.showAddModel = false
                            this.search()
                            this.$message.success(res.message)
                        }else{
                            this.$message.error(res.message)
                        }


                    })
            },
            showMaps(row) {
                this.isLock = row.IsLock
                // this.http.post('/api/Land_road_flag_early/getItemById?id='+row.ID,{}).then(res=>{
                    if(row.isShowPosi){
                        // row.XZFW = res.XZFW
                        // row.EarlyID = res.EarlyID ? res.EarlyID : row.ID
                        // row.QQID = res.ID!='00000000-0000-0000-0000-000000000000' ? res.ID : row.ID
                        this.dialogTableVisibleData = row;
                        this.dialogTableVisibleMap = true;
                    // }else{
                    //     this.$message.error(res.message)
                    }
                // })
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },
            changeSort(val) {
                if (val.order) {
                    this.sortData = {
                        field: val.prop,
                        sort: val.order == "ascending" ? "asc" : "desc",
                    };
                    if (val.prop == "SZDS") {
                        this.sortData.field = "DSBM";
                    }
                    if (val.prop == "SZX") {
                        this.sortData.field = "QXBM";
                    }
                } else {
                    this.sortData = {
                        field: "XMXH",
                        sort: "asc",
                    };
                }
                this.tableData = [];
                this.search();
            },
            handleClick(tab, event) {
            },
            closeDrawer(){
                this.$refs.detailForm.resetFields()
                this.search()
                // this.$refs.editForm.resetFields()
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            closeInnerDrawer(){
               this.search()
               setTimeout(()=>{
                   this.tableData.map((item,index)=>{
                       if (item.ID == this.rowData.ID){
                           this.openFlow(item)
                       }
                   })
               },1000)

            },
            accAdd(arg1, arg2) {
                var r1, r2, m;
                try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
                try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
                m = Math.pow(10, Math.max(r1, r2));
                return (arg1 * m + arg2 * m) / m;
            },
             openFlow(row){
                 this.rowData = row
                this.innerDrawerTitle = row.XMMC
                 this.innerDrawer = true
                 setTimeout(()=>{
                     this.qqjzid = row.ID
                     this.gg =  row.XMSX !=null &&  row.XMSX == '国高'? true : false
                     if ( this.gg){
                         this.GKWCD = Math.round(row.GKWCS / 18 *100) +'%'
                         this.CBSJWCD = Math.round(row.CBSJWCS / 21 *100) +'%'
                     }else{
                         this.GKWCD = Math.round(row.GKWCS / 16*100) +'%'
                         this.CBSJWCD = Math.round(row.CBSJWCS / 19 *100) +'%'
                     }
                     this.SGTSJWCD = Math.round(row.SGTSJWCS / 21 *100) +'%'
                     this.SGQZBWCD = Math.round(row.SGQZBWCS / 5 *100) +'%'
                 },500)
            },
            changeXztotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.xzyj == null || this.detail.xzyj ==  '' || !reg.test(this.detail.xzyj) ? 0 : parseFloat(this.detail.xzyj)
                var xz2 = this.detail.xzgs == null || this.detail.xzgs ==  '' || !reg.test(this.detail.xzgs) ? 0 : parseFloat(this.detail.xzgs)
                var xz3 = this.detail.xzej == null || this.detail.xzej ==  '' || !reg.test(this.detail.xzej) ? 0 : parseFloat(this.detail.xzej)
                var xz4 = this.detail.xzsj == null || this.detail.xzsj ==  '' || !reg.test(this.detail.xzsj) ? 0 : parseFloat(this.detail.xzsj)
                var xz5 = this.detail.xzsi == null || this.detail.xzsi ==  '' || !reg.test(this.detail.xzsi) ? 0 : parseFloat(this.detail.xzsi)
                var xz6 = this.detail.xzsdym == null || this.detail.xzsdym ==  '' || !reg.test(this.detail.xzsdym) ? 0 : parseFloat(this.detail.xzsdym)/1000
                var xz7 = this.detail.xzdw == null || this.detail.xzdw ==  '' || !reg.test(this.detail.xzdw) ? 0 : parseFloat(this.detail.xzdw)
                var xz8 = this.detail.xztdqym == null || this.detail.xztdqym ==  '' || !reg.test(this.detail.xztdqym) ? 0 : parseFloat(this.detail.xztdqym)/1000
                this.detail.xzhj = this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz5),xz6),xz7),xz8)
                // this.detail.xzhj = (xz1*1000/1000 +xz2*1000/1000 + xz3*1000/1000 +xz4*1000/1000 +xz5*1000/1000 + xz6*1000/1000 + xz7*1000/1000 + xz8*1000/1000)*1000/1000
            },
            changeJstotal(e,type){
                var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
                var xz1 = this.detail.jsgmyj == null || this.detail.jsgmyj ==  '' || !reg.test(this.detail.jsgmyj) ? 0 : parseFloat(this.detail.jsgmyj)
                var xz2 = this.detail.jsgmej == null || this.detail.jsgmej ==  '' || !reg.test(this.detail.jsgmej) ? 0 : parseFloat(this.detail.jsgmej)
                var xz3 = this.detail.jsgmsj == null || this.detail.jsgmsj ==  '' || !reg.test(this.detail.jsgmsj) ? 0 : parseFloat(this.detail.jsgmsj)
                var xz4 = this.detail.jsgmsdym == null || this.detail.jsgmsdym ==  '' || !reg.test(this.detail.jsgmsdym) ? 0 : parseFloat(this.detail.jsgmsdym)/1000
                var xz5 = this.detail.jsgmtdqym == null || this.detail.jsgmtdqym ==  '' || !reg.test(this.detail.jsgmtdqym) ? 0 : parseFloat(this.detail.jsgmtdqym)/1000
                var xz6 = this.detail.jsgmgs == null || this.detail.jsgmgs ==  '' || !reg.test(this.detail.jsgmgs) ? 0 : parseFloat(this.detail.jsgmgs)
                this.detail.jsgmhj = this.accAdd(this.accAdd(this.accAdd(this.accAdd(this.accAdd(xz1,xz2),xz3),xz4),xz5),xz6)
            },
            //保存
            saveCompany(){
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.newAdd ?  this.add(this.editModel) : this.update(this.editModel)

                    } else {

                        return false;
                    }
                });
            },
            //新增的
            add(params){
                params.id = '00000000-0000-0000-0000-000000000000'
                this.http.post('/api/Plan_buildcompany/AddBuildcompany?xmlx=0',params).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            //编辑的
            update(params){
                this.http.post('/api/Plan_buildcompany/UpdateBuildcompany?xmlx=0',params).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })

            },
            //删除
            deletedCompany(item){
                this.http.post('/api/Plan_buildcompany/DeleteBuildcompany',item).then(res=>{
                    if(res.status){
                        this.$message.success(res.message)
                        this.getFRDW()
                        this.editModel = {}
                        this.$refs.editForm.resetFields()
                        this.showEdit = false
                    }else{
                        this.$message.error(res.message)
                    }
                })
            },
            confirmCompany(){
                this.confirmSSW = JSON.parse(JSON.stringify(this.chek))
                if (this.chek.length != 0 ){
                        this.XMMC = this.chek[0].XMMC
                        this.SSWSSWGHXMID =this.chek[0].ID
                }else{
                    this.XMMC = ''
                    this.SSWSSWGHXMID = ''
                }

                this.assign = false
            },
            //编辑
            edit(item){
                this.newAdd = false
                this.title = '修改建设单位'
                this.editModel = JSON.parse(JSON.stringify(item))
                // this.editModel = JSON.parse(JSON.stringify(item))
                this.showEdit = true
            },
            addCompany(){
                this.newAdd = true
                this.title = '新增建设单位'
                this.editModel = {}
                this.showEdit = true
            },
            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                var reg =  /^[+]{0,1}(\d+)$/
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkNum  (rule, value, callback)  {
                if (!value) {
                    callback();
                }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                // if (this.newAdd == false){
                //
                //     return false
                // }
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        this.uploadLoading = false
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
                this.uploadLoading = false
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                            let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                            path = path[0] == "/" ? path.substring(1) : path;
                            var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                            this.download(
                                that.baseUrl + path + "?path=" + res.data,
                                name+'.zip'
                            );
                })
            },
            showFr(){
                var that = this
                // if(!this.isAdmin ){
                //     this.$message.warning('暂无此权限')
                //     return false
                // }
                this.getFRDW()
                this.frCompany = true
                if(this.addStatus){
                    if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                        this.$nextTick(()=>{
                            this.$refs.multipleTable1.clearSelection()
                        })
                    }else{
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyList.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            })
                        },1000)

                    }

                }else {
                    if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                        setTimeout(()=> {
                            this.$nextTick(() => {
                                that.$refs.multipleTable1.toggleRowSelection(that.frCompanyList.find((item) => {
                                    if (item.ID == that.XMFRDWID) {
                                        return item
                                    }
                                }))
                            }, 1000)
                        })
                    }
                }
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            openTable(){
                var that = this
                if(!this.isAdmin ){
                    this.$message.warning('暂无此权限')
                    return false
                }
                this.assign = true
                this.formInline = {}
                this.currentPage1 = 1
                this.getCompanyList()
                // if(this.addStatus && this.confirmSSW.length == 0){
                //     console.log('不行')
                //     this.$nextTick(()=>{
                //         this.$refs.multipleTable.clearSelection()
                //     })
                // }else{
                //     console.log('可以',that.detail,that.companyData)
                //     setTimeout(()=>{
                //      this.$nextTick(()=>{
                //          that.$refs.multipleTable.toggleRowSelection(that.companyData.find((item) => {
                //              if (item.XMMC == that.detail.sswghxmmc){
                //                  return item
                //              }
                //              }),
                //              true);
                //      })
                //  },1000)
                //
                //  // that.$refs.multipleTable.toggleRowSelection(that.chek[0],true);
                //
                // }

            },
            checkSelectable(row) {
                if (this.formInline.dwmc && row.dwmc.indexOf(this.formInline.dwmc) < 0) {
                    return false;
                } else {
                    return true;
                }
            },
            checkSelectable1(row) {
                if(!row.XMLXR){
                    row.XMLXR = ''
                }
                if (this.frForm.dwmc && row.DWMC.indexOf(this.frForm.dwmc) < 0 ||
                    this.frForm.xmzrr && row.XMZRR.indexOf(this.frForm.xmzrr) < 0 ||
                    this.frForm.xmlxr && row.XMLXR.indexOf(this.frForm.xmlxr) < 0 ) {
                    return false;
                } else {
                    return true;
                }
            },
            confirmChoose(){

            },
            tableRowClassName(row, index) {
                return this.checkSelectable1(row.row) ? "" : "hidden";
            },
            warningClassName(row,index){
                if (row.row.cqsj === 0){
                    return 'warning-row'
                }else{
                    return 'danger-row'
                }
            },
            //选择建设单位
            selectCompany(val){
                var that = this
                if(val.length > 1){
                    this.$refs.multipleTable.clearSelection()
                    this.$refs.multipleTable.toggleRowSelection(val.pop())
                }else{
                    this.chek = val;

                }
            },
            conformXmlxr(){
                if (this.chekfr.length != 0){

                this.confirmXMFR =  this.chekfr
                this.XMFRDW = this.confirmXMFR[0].DWMC
                this.XMFRDWID = this.confirmXMFR[0].ID
                }else{
                    this.confirmXMFR=[{XMZRR:'',XMZRRLXDH:''}],

                        this.XMFRDW = ''
                    this.XMFRDWID = ''
                }
                this.frCompany = false
            },
           async selectCompany1(val){
                if(val.length > 1){
                    this.$refs.multipleTable1.clearSelection()
                    this.$refs.multipleTable1.toggleRowSelection(val.pop())
                }else{
                    this.chekfr = val;
                }
            },
            //十四五项目列表
            getXMLB(){
                this.http.post('/api/V_plan_high_national_early/GetPageData',{}).then(res=>{
                })
            },
            //获取法人单位    /api/Plan_buildcompany/getBuildCompanyList
            getFRDW(){
                var that =this
                this.frCompanyList = []
                this.http.post('/api/Plan_buildcompany/GetPageData', {rows:1000}).then(res=>{
                    console.log(res)
                    res.rows.map((item,index)=>{
                        // if (item.XMLX == 'Road'){
                            this.frCompanyList.push(item)
                        // }
                    })

                    if(this.addStatus){
                        if ( this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR == ''){
                            this.$nextTick(()=>{
                                this.$refs.multipleTable1.clearSelection()
                            })
                        }

                    }else {
                        if (this.confirmXMFR.length != 0 && this.confirmXMFR[0].XMZRR != ''){
                            setTimeout(()=> {
                                this.$nextTick(() => {
                                    that.$refs.multipleTable1.toggleRowSelection(that.frCompanyList.find((item) => {
                                        if (item.ID == that.XMFRDWID) {
                                            return item
                                        }
                                    }))
                                }, 1000)
                            })
                        }
                    }
                })
                console.log(this.frCompanyList)
            },
            searchCompanyList(){
                this.getCompanyList(1)
            },
            getCompanyList(page){
                var that = this
                // /api/Plan_road/GetPageData   /api/Plan_high_national_early/queryPlanRoad
                this.http.post('/api/Plan_high_national_early/queryPlanRoad',  this.postData1(page)).then(res=> {
                    this.companyData = res.rows
                    this.total1 = res.total
                    this.$nextTick(() => {
                                 that.$refs.multipleTable.toggleRowSelection(that.companyData.find((item) => {
                                     if (item.XMMC == that.detail.sswghxmmc){
                                         return item
                                     }
                                     }),
                                     true);


                        // this.companyData.forEach((row) => {
                        //     this.$refs.multipleTable.toggleRowSelection(this.companyData.find((item) => {
                        //             return item.IsSelf == true;
                        //         }),
                        //         true);
                        // });

                        // this.companyData.map((item,index)=>{
                        //     if (item.IsSelf === true){
                        //         console.log(212121)
                        //         that.$refs.multipleTable.toggleRowSelection(item,true);
                        //     }
                        // })
                    })
                })
            },
            addRoad(){
                var that = this
                this.addStatus = true
                this.confirmSSW = []
                this.currentPage1 = 1
                this.showAddModel = true
                this.totalSqu = 0
                this.activeName = '0'
                this.detail = {
                    jsxmydys:{pfwj:[]},
                    ptzjcns:{pfwj:[]},
                    stbcpjpf:{pfwj:[]},
                    ghxzyjs:{pfwj:[]},
                    hjyxpjpf:{pfwj:[]},
                    jnpg:{pfwj:[]},
                    shwd:{pfwj:[]},
                    xmjy:{pfwj:[]},
                    gky:{pfwj:[]},
                    cbsj:{pfwj:[]},
                    sgt:{pfwj:[]},
                    zsyd:{pfwj:[]},
                    sgxk:{pfwj:[]},
                    jtb:{pfwj:[]},
                    qt:{pfwj:[]}
                }
                this.XMFRDW =''
                this.XMFRDWID =''
                this.XMMC = ''
                this.SSWSSWGHXMID = ''
                this.chekfr =[{XMZRR:'',XMZRRLXDH:''}]
                this.confirmXMFR =[{XMZRR:'',XMZRRLXDH:''}]
                this.chek = []

            },
            //退出编辑
            closeEdit(){
                this.showMessage = false
                this.showEditModel = false
                this.showAddModel = false
                this.detail = {
                    jsxmydys:{pfwj:[]},
                    ptzjcns:{pfwj:[]},
                    stbcpjpf:{pfwj:[]},
                    ghxzyjs:{pfwj:[]},
                    hjyxpjpf:{pfwj:[]},
                    jnpg:{pfwj:[]},
                    shwd:{pfwj:[]},
                    xmjy:{pfwj:[]},
                    gky:{pfwj:[]},
                    cbsj:{pfwj:[]},
                    sgt:{pfwj:[]},
                    zsyd:{pfwj:[]},
                    sgxk:{pfwj:[]},
                    jtb:{pfwj:[]},
                    qt:{pfwj:[]}
                }
            },
            //删除图片
            deleted(index,type){
                this.detail[type].pfwj.splice(index,1)
            },
            activeChange(e){
            },
        changeTotal(val,type){
                var that = this
            var reg =  /^(?:[1-9]\d*|0)(?:\.\d+)?$/
            if (val && !reg.test(val)) {
                    return false
                }
            if (type == 'nyd'){
                if(this.detail.ydwlyd != '' && this.detail.ydwlyd != null){
                    this.totalSqu = val == '' ? that.accAdd(0,parseFloat(this.detail.ydwlyd))  :  that.accAdd( parseFloat(val) ,  parseFloat(this.detail.ydwlyd))
                }else{
                    this.totalSqu = val == '' ? 0 : parseFloat(val)*1000/1000
                }
            }else{
                if(this.detail.ydnyd != '' && this.detail.ydnyd != null){
                    this.totalSqu =  val == '' ? that.accAdd(0 , parseFloat(this.detail.ydnyd)) : that.accAdd(parseFloat(val)  , parseFloat(this.detail.ydnyd))
                }else{
                    this.totalSqu = val == '' ? 0: parseFloat(val)
                }
            }

        },
            getPieData(res,name,data){
                data = []
                console.log(this.piegs)
                res.map((item)=>{
                    if(item.QQGLLX == name){
                        data.push(item)
                    }
                })
                var a = {GKWKZ:0,GK:0,CBSJ:0,SGT:0,Total:0,}
                data.map((item,indx)=>{
                    a.Total += item.Total
                    a.GKWKZ += item.GKWKZ
                    a.GK += item.GK
                    a.CBSJ += item.CBSJ
                    a.SGT += item.SGT
                })
                return a
            },
            getCurrent(){
                var that = this
                this.pieTotal = 0
                // var searchData = {
                //     wheres:JSON.stringify([{  Name:'Start', Value:this.searchYear.Start},{  Name:'End', Value:this.searchYear.End}]),
                // };
                this.http.post('/api/Plan_high_national_early/EarlyStatisticsCurrent',{}).then(res=>{
                    res.map((item)=>{
                        this.pieTotal +=( item.Total-item.GKWKZ)
                    })
                    this.piegs = this.getPieData(res,'高速公路',this.piegs)
                    this.piegd = this.getPieData(res,'普通国道',this.piegd)
                    this.piesd = this.getPieData(res,'普通省道',this.piesd)
                    this.tjtotal = this.pieTotal
                    this.ky = this.piegs.GK + this.piegd.GK + this.piesd.GK
                    this.cs = this.piegs.CBSJ + this.piegd.CBSJ + this.piesd.CBSJ
                    this.ss = this.piegs.SGT + this.piegd.SGT + this.piesd.SGT
                })
            },
        setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            save(){
                this.$refs.detailForm.validate((valid) => {
                    this.saveLoading = true
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        var checkList = []
                        params.jsxz = params.jsxz.join(";")
                        params.jsnr = params.jsnr.join(";")

                        params.sswghxmmc = this.XMMC
                        params.sswghxmid = this.SSWSSWGHXMID
                        params.xmfrdw = this.XMFRDW
                        params.xmfrdwid = this.XMFRDWID
                        params.isLock = this.isLock
                        if(this.chekfr.length != 0){
                            params.qqgzfzr = this.chekfr[0].XMZRR
                            params.lxdh = this.chekfr[0].XMZRRLXDH
                        }
                        if (this.addStatus){
                            params.id = '00000000-0000-0000-0000-000000000000'
                            this.http.post('/api/Plan_high_national_early/AddPlanHighNationalEarly',params).then(res=>{
                                this.saveLoading = false
                                if(res.status){
                                    // this.showAddModel = false
                                    this.qqjzid = res.data.id
                                    this.addStatus = false
                                    this.search()
                                    this.$message.success(res.message)
                                }else{
                                    this.$message.error(res.message)
                                }


                            })
                        }else{
                            this.http.post('/api/Plan_high_national_early/UpdatePlanHighNationalEarly',params).then(res=>{
                                this.saveLoading = false
                                if(res.status){
                                    this.showAddModel = false
                                    this.search()
                                    this.$message.success(res.message)
                                }else{
                                    this.$message.error(res.message)
                                }


                            })
                        }
                    } else {
                        this.saveLoading = false
                        return false;
                    }
                });
            },
            cancel(){
                this.showMessage = true
                this.showEdit = false
                this.$refs.editForm.resetFields()
                this.$refs.editModel.resetFields()

            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                this.http.post("/api/Plan_high_national_early/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail[type].pfwj.push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
            changeCollapse(val){
            },
            chechfieldxmlx() {
                return this.gllx == "普通省道" ? false : true;
            },
            chechfieldxmlb() {
                return this.gllx == "普通省道" ? false : true;
            },
            checkfieldjsxz() {
                return this.gllx == "高速公路" ? true : false;
            },
            checkfieldxmxz(e) {
                var arr = [];
                if (this.gllx == "高速公路") {
                    arr = [
                        "“十三五”续建项目",
                        "2021年新开工项目",
                        "“十四五”其他项目",
                        "“十四五”谋划项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
                if (this.gllx == "普通国道") {
                    arr = [
                        "“十三五”续建项目",
                        "“十四五”新开工项目",
                        "谋划项目",
                        "“十四五”谋划项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
                if (this.gllx == "普通省道") {
                    arr = [
                        "“十三五”续建项目",
                        "2021年已安排计划项目",
                        "2022年建设项目",
                        "2023年建设项目",
                        "2024-2025年建设项目",
                    ];
                    return arr.indexOf(e) >= 0 ? false : true;
                }
            },
            closeDetail(){
                this.showDetail = false
                this.detail = {}

            },
            openDetailsView(row) {
                this.showDetail = true
                this.detail = row
                this.detail.gkbzwj = this.detail.gkbzwj == null || this.detail.gkbzwj.length === 0 ? [] :JSON.parse(this.detail.gkbzwj)
                this.detail.gkpsyj = this.detail.gkpsyj == null|| this.detail.gkbzwj.gkpsyj === 0  ? [] :JSON.parse(this.detail.gkpsyj)
                this.detail.gkpfwj = this.detail.gkpfwj == null || this.detail.gkbzwj.gkpfwj === 0 ? [] :JSON.parse(this.detail.gkpfwj)
                this.detail.cbsjbzwj = this.detail.cbsjbzwj == null|| this.detail.gkbzwj.cbsjbzwj === 0  ? [] :JSON.parse(this.detail.cbsjbzwj)
                this.detail.cbsjpswj = this.detail.cbsjpswj == null|| this.detail.gkbzwj.cbsjpswj === 0  ? [] :JSON.parse(this.detail.cbsjpswj)
                this.detail.cbsjpfwj = this.detail.cbsjpfwj == null || this.detail.gkbzwj.cbsjpfwj === 0 ? [] :JSON.parse(this.detail.cbsjpfwj)
                this.detail.sgtsjbzwj = this.detail.sgtsjbzwj == null|| this.detail.gkbzwj.sgtsjbzwj === 0  ? [] :JSON.parse(this.detail.sgtsjbzwj)
                this.detail.sgtsjpswj = this.detail.sgtsjpswj == null || this.detail.gkbzwj.sgtsjpswj === 0 ? [] :JSON.parse(this.detail.sgtsjpswj)
                this.detail.sgtsjpfwj = this.detail.sgtsjpfwj == null|| this.detail.gkbzwj.sgtsjpfwj === 0  ? [] :JSON.parse(this.detail.sgtsjpfwj)
                this.detail.sgqzbydpfwj = this.detail.sgqzbydpfwj == null || this.detail.gkbzwj.sgqzbydpfwj === 0 ? [] :JSON.parse(this.detail.sgqzbydpfwj)
                this.detail.sgqzbsgxdpfwj = this.detail.sgqzbsgxdpfwj == null || this.detail.gkbzwj.sgqzbsgxdpfwj === 0 ? [] :JSON.parse(this.detail.sgqzbsgxdpfwj)
                this.detail.xygtkjghwj = this.detail.xygtkjghwj == null|| this.detail.gkbzwj.xygtkjghwj === 0  ? [] :JSON.parse(this.detail.xygtkjghwj)
            },
            openDetailsEdit(row) {
                var that = this
                this.showAddModel = true
                this.activeName = '0'
                // this.$refs.detailForm.resetFields();
                this.addStatus = false
                setTimeout(()=>{
                    this.qqjzid = row.ID
                    this.gg =  row.XMSX !=null &&  row.XMSX == '国高'? true : false
                },500)
                this.http.post('/api/Plan_high_national_early/getItemById?id='+row.ID,{}).then(res=>{
                    this.detail = res.data
                    this.detail.jsxz = this.detail.jsxz ? this.detail.jsxz.split(';') : []
                    this.detail.jsnr = this.detail.jsnr ? this.detail.jsnr.split(';') : []
                    this.isLock = res.data.isLock
                    this.totalSqu = res.data.ydhj
                    this.XMMC = res.data.sswghxmmc
                    this.SSWSSWGHXMID = res.data.sswghxmid
                    this.XMFRDW = res.data.xmfrdw
                    this.XMFRDWID = res.data.xmfrdwid
                    this.chekfr = [{ID:res.data.xmfrdwid,DWMC: res.data.xmfrdw,XMZRR: res.data.qqgzfzr,XMZRRLXDH:res.data.lxdh}]
                    this.confirmXMFR = [{ID:res.data.xmfrdwid,DWMC: res.data.xmfrdw,XMZRR: res.data.qqgzfzr,XMZRRLXDH:res.data.lxdh}]

                })
            },
            exportData() {
                var that = this
                this.http.post('/api/Plan_high_national_early/ExportComprehensiveEarlyProgress',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '前期项目表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            exportData1() {
                var that = this
                this.uploadLoading = true
                this.http.post('/api/Plan_high_national_early/EarlyTemplateWrite',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '前期进展统计表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            exportDataOnce(id) {
                var that = this
                this.uploadLoading = true
                this.http.post('/api/Plan_high_national_early_node/ExportEarlyPromotionTable?id='+id,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '前期进展统计表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            exportData2() {
                var that = this
                var zip = new JSZip();
                var promises = [];
                var blogTitle = '前期工作推进表';
                let cache = {};
                this.uploadLoading = true
                this.selectPro.map((item,index) =>{
                        setTimeout(()=> {
                            this.http.post('/api/Plan_high_national_early_node/ExportEarlyPromotionTable?id=' + item, {}).then(res => {
                                if (!res.status) {
                                    this.$message.error(res.message)
                                    return false
                                }
                                let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                                path = path[0] == "/" ? path.substring(1) : path;
                                var timestamp = new Date().getTime();
                                // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                                this.loadList.push({
                                    url: that.baseUrl + path + "?path=" + res.data,
                                    fileName: '前期工作推进计划表' + timestamp + '.xlsx'
                                })
                                    if (this.loadList.length == this.selectPro.length) {
                                        for (let item of this.loadList) {
                                            // item.path为文件链接地址

                                            const promise = that.getImgArrayBuffer(item.url, item.fileName).then((data) => {
                                                // 下载文件, 并存成ArrayBuffer对象(blob)
                                                zip.file(item.fileName, data, {binary: true}); // 逐个添加文件
                                                cache[item.fileName] = data;
                                            });
                                            promises.push(promise);
                                        }
                                        Promise.all(promises).then(() => {
                                            zip.generateAsync({type: "blob"}).then((content) => {
                                                // 生成二进制流
                                                FileSaver.saveAs(content, blogTitle); // 利用file-saver保存文件  自定义文件名
                                            });
                                            this.$refs.multipleTable.clearSelection();
                                            // setTimeout(()=>{
                                            //     that.$refs.multipleTable.clearSelection();
                                            // },2000)

                                        }).catch((res) => {
                                            alert("文件压缩失败");
                                        });
                                    }
                            })
                        },index*1000)
                })

                    // this.download(
                    //     that.baseUrl + path + "?path=" + res.data,
                    //     '前期工作推进计划表'+timestamp+'.xlsx'
                    // );
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getImgArrayBuffer(url,fileName) {
                    return new Promise((resolve, reject) => {
                        //通过请求获取文件blob格式
                        let xmlhttp = new XMLHttpRequest();
                        xmlhttp.open("GET", url, true);
                        xmlhttp.setRequestHeader("Content-type", "application/json");
                        xmlhttp.setRequestHeader(
                            "Authorization",
                            store.getters.getToken()
                        );
                        xmlhttp.responseType = "blob";
                        xmlhttp.onload = function () {
                            if (this.status == 200) {
                                resolve(this.response);
                            } else {
                                reject(this.status);
                            }
                        };
                        xmlhttp.send();
                    });
            },
            exportData3() {
                var that = this
                this.uploadLoading = true
                this.http.post('/api/Plan_high_national_early_node/ExportEarlyWarningInfo',{page:1,rows:1000,}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '督导项目表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                var postData = { SZDS: "", SZX: "" };
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        res.map((r) => {
                            r.value = r.label;
                            r.children.map((rr) => {
                                rr.value = rr.label;
                            });
                        });
                        this.regions = res;

                    });
            },
            //查询
            search() {
                this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.getPageTotal(); //查汇总
                this.http
                    .post(
                        "/api/Plan_high_national_early/GetData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        this.regions =  [
                            {
                                value: "",
                                label: "黑龙江省",
                            },
                        ]
                        this.getRegion()
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                            this.tableData.filter((item) => {
                                if (this.noLine.indexOf(item.XMMC) > -1) {
                                    item.isShowPosi = false
                                }else{
                                    item.isShowPosi = true
                                }
                            });
                        }
                    });
            },
            postData(gllx) {
                var szds = "";
                var ssx = "";
                var reg = JSON.parse(JSON.stringify(this.region))
                if (reg.length > 0) {
                    szds = reg[0];
                    if (reg.length > 1) {
                        ssx = reg[1];
                    }
                }

                //项目名称
                var query_xmmc = {
                    Name: "XMMC",
                    Value: this.xmmc,
                    DisplayType: "like",
                };
                //项目gx
                var query_gllx = {
                    Name: "QQGLLX",
                    Value: this.gllx,
                    DisplayType: "Equal",
                };
                //法人单位
                var query_frdw = {
                    Name: "XMFRDW",
                    Value: this.frdw,
                    DisplayType: "like",
                };
                //所属地市
                var query_ssds = {
                    Name: "SZDS",
                    Value: szds.indexOf('市')!== -1 ?szds.replace('市','') : szds,
                    DisplayType: "like",
                };
                //所属县
                var query_ssx = {
                    Name: "SZX",
                    Value: ssx.indexOf('市')!== -1 ?ssx.replace('市','') : ssx.indexOf('区')!== -1 ? ssx.replace('区','') :ssx.indexOf('县')!== -1 ?  ssx.replace('县','') : ssx,
                    DisplayType: "like",
                };
                //项目类别
                var xmlbArray = [];
                this.checkedXmlb.forEach((element) => {
                    xmlbArray.push(element);
                });
                var query_xmlb = {
                    Name: "XMLB",
                    Value: xmlbArray.join(","),
                    DisplayType: "checkbox",
                };
                //建设性质
                var jsxzArray = [];
                this.checkedJsxz.forEach((element) => {
                    jsxzArray.push(element);
                });
                var query_jsxz = {
                    Name: "JSXZ",
                    Value: jsxzArray.join(","),
                    DisplayType: "checkbox",
                };
                //项目性质
                var xmxzArray = [];
                this.checkedXmxz.forEach((element) => {
                    xmxzArray.push(element);
                });
                var query_xmxz = {
                    Name: "XMXZ",
                    Value: xmxzArray.join(","),
                    DisplayType: "checkbox",
                };
                //建设规模
                var jsgmArray = [];
                this.checkedJsgm.forEach((element) => {
                    jsgmArray.push(element);
                });
                var query_jsgm = {
                    Name: "JSGMZT",
                    Value: jsgmArray.join(","),
                    DisplayType: "checkbox",
                };
//工可
//                 var gk = [];
//                 this.checkedGk.forEach((element) => {
//                     gk.push(element);
//                 });
//                 var query_gk = {
//                     Name: "GKBLZT",
//                     Value: gk.join(","),
//                     DisplayType: "checkbox",
//                 };
                var xmbq = ''
                if(this.checkedLabel.length  != 0){
                    if(this.checkedLabel[0] == '全部'){
                        xmbq = ''
                    }else{
                        xmbq = '2022年续建,2023年新开工'
                    }
                }
                var query_xmbq = {
                    Name: "TagId",
                    Value: this.checkedLabel.join(","),
                    DisplayType: "checkbox",
                };

                var query_bq = {
                    Name: "BQ",
                    Value: xmbq,
                    DisplayType: "checkbox",
                };
                var query_gk = {}
                var query_cs = {}
                var query_sgt = {}
                var a = []
                if(this.checkedqq.length != 0){
                    this.checkedqq.map(item=>{
                        a.push(item[0])
                    })
                }
                if (a.indexOf('0')  != -1){
                    query_gk = {
                        Name: "GKBLZT",
                        Value: '1,2',
                        DisplayType: "checkbox",
                    };
                }
                  if (a.indexOf('1')  != -1){
                      query_cs = {
                        Name: "CBSJBLZT",
                        Value: '1,2',
                        DisplayType: "checkbox",
                    };
                }
                if (a.indexOf('2') != -1){
                    query_sgt = {
                        Name: "SGTBLZT",
                        Value: '1,2',
                        DisplayType: "checkbox",
                    };
                }
                // var cbsj = []
                // this.checkedCbsj.forEach((element) => {
                //     cbsj.push(element);
                // });
                // var query_qq = {
                //     Name: "CBSJBLZT",
                //     Value: cbsj.join(","),
                //     DisplayType: "checkbox",
                // };

                // var sgt = []
                // this.checkedSgtsj.forEach((element) => {
                //     sgt.push(element);
                // });
                // var query_sgt = {
                //     Name: "SGTBLZT",
                //     Value: sgt.join(","),
                //     DisplayType: "checkbox",
                // };
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "NoSort",
                    Order: "",
                    wheres: JSON.stringify([
                        query_bq,
                        query_gk,
                        query_cs,
                        query_sgt,
                        query_jsxz,
                        query_xmxz,
                        query_gllx,
                        query_frdw,
                        query_xmlb,
                        query_xmmc,
                        query_ssds,
                        query_ssx,
                        query_jsgm

                    ]),
                };
                return postData;
            },
            postData1(page){

                    //项目名称
                    var query_xmmc = {
                        Name: "XMMC",
                        Value: this.formInline.XMMC,
                        DisplayType: "like",
                    };

                        if(page){
                            this.currentPage1 = page
                        }
                    var postData = {
                        page: this.currentPage1,
                        rows: this.pageSize1,
                        Sort: "DSBM",
                        Order: "asc",
                        wheres: JSON.stringify([
                            query_xmmc
                        ]),
                    };
                if(!this.addStatus){
                    postData.value = this.detail.id
                }
                    return postData;
            },
            clearCondition() {
                this.region = [""];
                this.xmmc = "";
                this.frdw = "";
                this.xmnd = "";
                this.qqgzjz = [];
                this.checkedXmlb = [];
                this.checkedJsgm = []
                this.checkedGk = [];
                this.checkedCbsj = [];
                this.checkedSgtsj = [];
                this.checkedXmxz = [];
                this.checkedJsxz = [];
            },
            handleChange(value) {
                // this.checkedXmxz = [];
                // this.search();
            },
            handleCheckAllJsxzChange(val) {
                this.checkedJsxz = val ? this.jsxzList : [];
                this.isIndeterminateJsxz = false;
            },
            //项目类别
            handleCheckAllXmlbChange(val){
                    this.checkedXmlb = val ? this.xmlbList : [];
                this.isIndeterminateXmlb = false;
            },
            //建设规模
            handleCheckAllJsgmChange(val){
                this.checkedJsgm = val ? this.jsgmList : [];
                this.isIndeterminateJsgm = false;
            },
            //工可
            handleCheckAllGkChange(val){
                this.checkedGk = val ? this.gkList : [];
                this.isIndeterminateGk = false;
            },
            //初步设计
            handleCheckAllCbsjChange(val){
                this.checkedCbsj = val ? this.cbsjList : [];
                this.isIndeterminateCbsj = false;
            },
            //施工图设计
            handleCheckAllSgtsjChange(val){
                this.checkedSgtsj = val ? this.sgtsjList : [];
                this.isIndeterminateSgtsj = false;
            },
            handleCheckAllXmlxChange(val) {
                if (this.gllx == "普通省道") {
                    this.checkedXmlx = val ? this.xmlxList : [];
                }
                this.isIndeterminateXmlx = false;
            },
            handleCheckedXmxzChange(value) {
                let checkedCount = value.length;
                this.checkAllXmxz = checkedCount === this.xmxzList.length;
                this.isIndeterminateXmxz =
                    checkedCount > 0 && checkedCount < this.xmxzList.length;
            },
            handleCheckedJsxzChange(value) {
                let checkedCount = value.length;
                this.checkAllJsxz = checkedCount === this.jsxzList.length;
                this.isIndeterminateJsxz =
                    checkedCount > 0 && checkedCount < this.jsxzList.length;
            },
            handleCheckedXmlbChange(value){
                let checkedCount = value.length;
                this.checkAllXmlb = checkedCount === this.xmlbList.length;
                this.isIndeterminateXmlb =
                    checkedCount > 0 && checkedCount < this.xmlbList.length;
            },
            handleCheckedJsgmChange(value){
                let checkedCount = value.length;
                this.checkAllJsgm = checkedCount === this.jsgmList.length;
                this.isIndeterminateJsgm =
                    checkedCount > 0 && checkedCount < this.jsgmList.length;
            },
            handleCheckedXmlxChange(value) {
                let checkedCount = value.length;
                this.checkAllXmlx = checkedCount === this.xmlxList.length;
                this.isIndeterminateXmlx =
                    checkedCount > 0 && checkedCount < this.xmlxList.length;
            },
            handleCheckedGkChange(value) {
                let checkedCount = value.length;
                this.checkAllGk = checkedCount === this.gkList.length;
                this.isIndeterminateGk =
                    checkedCount > 0 && checkedCount < this.gkList.length;
            },
            handleCheckedCbsjChange(value) {
                let checkedCount = value.length;
                this.checkAllCbsj = checkedCount === this.cbsjList.length;
                this.isIndeterminateCbsj =
                    checkedCount > 0 && checkedCount < this.cbsjList.length;
            },
            handleCheckedSgtsjChange(value) {
                let checkedCount = value.length;
                this.checkAllSgtsj = checkedCount === this.sgtsjList.length;
                this.isIndeterminateSgtsj =
                    checkedCount > 0 && checkedCount < this.sgtsjList.length;
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
            handleSizeChange1(val) {
                this.pageSize1 = val;
                this.getCompanyList()
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.getCompanyList()

            },
        },
        watch: {
            activeIndex(v) {
                if (v) {
                    this.tableHeight = document.body.offsetHeight - 450;
                } else {
                    this.tableHeight = document.body.offsetHeight - 245;
                }
            },
        },
    };
</script>
<style>
    .danger-row{
        background: rgb(254, 240, 240) !important;
    }
    .warning-row{
        background: rgb(253, 246, 236) !important;
    }
    .hidden {
        display: none ;
    }
</style>
<style lang="less" scoped>
    .roadFront{
        font-family: "Microsoft YaHei 微软雅黑";
    }
    /deep/.el-step__title.is-process{
        color: #409EFF !important;
        border-color: #409EFF !important;
    }
    /deep/.el-collapse-item__header{
        height: 32px;
        padding:0 15px;
        background: #F2F8FE !important;
    }
    .title{
        padding: 0 16px;
        min-width: 98px;
        height: 32px;
        background: #409EFF;
        opacity: 1;
        line-height: 32px;
        text-align: center;
        color: #fff;
    }
    .file{
        margin-right: 10px;
        display: inline-block;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #B3D8FF;
        opacity: 1;
        border-radius: 4px;
        color: #409EFF;
        a{
            height: 32px;
            line-height: 32px;
        }
    }
    .roadFront{
        /deep/.el-collapse{
            margin-top: 20px;
        }
        /deep/.el-form--inline .el-form-item{
            margin-right: 20px;
        }
        /*/deep/.el-collapse-item__content {*/
        /*    padding:10px 15px 20px 15px  !important;*/
        /*}*/
        .editModal{
            /deep/.el-input__inner{
                width: 330px;
                height: 35px;
                line-height: 35px;
            }

        }
        .dwmcWrap{
            /deep/.el-input__inner{
                width: 330px;
            }

        }
        /deep/.box-content .el-form-item__label{
            margin-right: 0px;
            text-align: left;
            color: #034761;
            background:unset;
        }
        /deep/ .formBox .el-input--suffix{
            width: 160px;
        }
        .col{
            /deep/.el-form-item__label{
                background: #F2F8FE;
                color: #78818E;
                line-height: 30px;
                float: unset;
            }
        }
        /deep/.el-form-item__label{
            margin-right: 4px;
            color: #000;
            text-align: center;
            background: #FAFAFA;
        }
        .search{
            /deep/.el-form-item__label{
                background: unset;
            }
        }
    }

    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .editWrap{
        /deep/.el-drawer__body{
            padding: 12px 15px 0px;
            /*padding-bottom: 60px;*/
        }
        .el-row{
            margin-bottom: 4px;
        }
    }

    /deep/.el-form-item__error--inline {
        position: relative;
        top: auto;
        display: block;
        left: auto;
        margin-left: 10px;
    }
    /deep/.el-step.is-simple:not(:last-of-type) .el-step__title{
        max-width: 70%;
    }
    .bigTitle{
        width: 50%;
        margin: 24px 0;
        /*border-bottom: 1px solid #B3D8FF;*/
        span{
            color: #333;
            font-weight: 600;
            padding: 8px 12px;
            height: 28px;
            background: #B3D8FF;
            opacity: 1;
            border-radius: 0px 14px 0px 0px;
        }

    }
    a{
        border: none;
    }
    .dwmcWrap{
        cursor: pointer;
        width: 330px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border:1px solid #DCDFE6;
        border-radius: 5px;
        .dwmc{
            padding-left: 15px;
            text-align: left;
            width: 310px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 35px;
            background: #ffffff;
            border-radius: 5px 0 0 5px;
            line-height: 35px;

        }
        .plus{
            border-left: 1px solid #f9f9f9;
            width: 20px;
            height: 35px;
            background: #fafafa;
            border-radius: 0 5px 5px 0;
            i{
                font-size: 16px;
            }
        }
    }
    // 隐藏全选框
    /deep/ .DisableSelection > .cell {
        display: none !important;
    }
    /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background: #409eff;
    }
    /deep/.el-dialog{j
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .col{
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        margin-bottom: 60px;
        .col-1{
            padding:10px 20px 0;
            width: 330px;
            background: #F2F8FE;
            height: 1100px;
            border-radius: 5px;
        }
    }
    .deliver{
        margin: 15px 0;
        width: 100%;
        height: 1px;
        border-bottom: 1px dotted #999999;
    }
    .el-divider__text{
        background: #F2F8FE;
    }
.meng{
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.4);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .close{
        cursor: pointer;
        position: absolute;
        top: 700px;
        width: 32px;
        height: 32px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .cardRed{
        width: 546px;
        min-height: 361px;
        background: #FD2929;
        border-radius: 30px 30px 30px 30px;
        .cardW{
            margin: -50px 0 0 -30px;
            width: 600px;
            min-height: 393px;
            background: #FFFFFF;
            box-shadow: 0px 3px 10px 1px rgba(0,0,0,0.16);
            border-radius: 30px 30px 30px 30px;
            padding: 1px;
            color: #000;
            font-size: 16px;
            .yjicon{
                margin: -87px auto 0;
                width: 174px;
                height: 174px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .cardCont{
                margin: 0 auto;
                width: 546px;
                height: 180px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
            }
            .cardButton{
                cursor:pointer;
                text-align: center;
                margin: 24px auto 0;
                color:#409EFF;
                text-decoration-line: underline;
            }
            .jdmc{
                font-weight: bold;
                color: #000000;
                font-size: 16px;
            }
            .cq{
                font-size: 18px;
                font-weight: bold;
                color: #FB2828;
            }
            .lq{
                font-size: 18px;
                font-weight: bold;
                color: #FD6B24;
            }
        }
    }
}
    ::v-deep .mapdialog1 .el-dialog{
        margin-top: 0 !important;

    }
</style>
