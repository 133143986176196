<template>
    <div class="wrap" :style="{height:height+'px'}">
        <div class="flex" style="margin: 0px 0 12px">
            <div class="slfj">矢量附件</div>
            <div class="star">* 根据前期工作进度，上传最后阶段矢量文件即可。文件名称以路线编号+规划库中的项目名称命名，dxf或dwg格式；统一采用2000国家打的坐标系，并标明中央子午线</div>
        </div>
        <table style="text-align:center">
            <tr>
                <td>
                    <div class="label2"></div>
                </td>
                <td class="label3">
                    公路中心线平曲线要素
                </td>
                <td class="label3">
                    <div>公路用地边界文件</div>
                </td>
            </tr>
            <tr>
                <td class="label2">
                    <div>规划选址</div>
                </td>
                <td>
                    <div class="flex fjWrap">
                        <el-form-item>
                            <label class="fright"  >
                                <i aria-hidden="true" :style="{cursor: isLock ? 'not-allowed' :''}" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                <input :disabled="isLock"   style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple"  @change="onSubmit($event,'GHDZZXX')" />
                            </label>
                        </el-form-item>
                        <div>
                            <div class="file" v-for="(item,index) in detail.GHDZZXX" :key="index">
<!--                                <a target="_blank" :download="item.name" :href="baseUrl+item.url+item.name">-->
                                <div @click="exportFile(item.url,item.name)">
                                    <img src="../../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
<!--                                </a>-->
                                </div>
                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'GHDZZXX')">
                                    <template #reference>
                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="flex fjWrap">
                        <el-form-item>
                            <label class="fright">
                                <i aria-hidden="true" :style="{cursor: isLock ? 'not-allowed' :''}" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                <input :disabled="isLock"  style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple"  @change="onSubmit($event,'GHDZYDT')" />
                            </label>
                        </el-form-item>
                        <div>
                            <div class="file" v-for="(item,index) in detail.GHDZYDT" :key="index">
                                <div @click="exportFile(item.url,item.name)">
                                <img src="../../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                </div>
                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'GHDZYDT')">
                                    <template #reference>
                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="label2">
                    <div>工可研</div>
                </td>
                <td>
                    <div class="flex fjWrap">
                        <el-form-item>
                            <label class="fright">
                                <i aria-hidden="true" :style="{cursor: isLock ? 'not-allowed' :''}" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                <input :disabled="isLock"  style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple"  @change="onSubmit($event,'GKYZXX')" />
                            </label>
                        </el-form-item>
                        <div>
                            <div class="file" v-for="(item,index) in detail.GKYZXX" :key="index">
                                <div @click="exportFile(item.url,item.name)">
                                <img src="../../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                </div>
                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'GKYZXX')">
                                    <template #reference>
                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="flex fjWrap">
                        <el-form-item>
                            <label class="fright">
                                <i aria-hidden="true" :style="{cursor: isLock ? 'not-allowed' :''}" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                <input :disabled="isLock"  style="width: 72px" type="file" v-show="false" name="file" ref="file"  id="fileInput" multiple="multiple" @change="onSubmit($event,'GKYYDT')" />
                            </label>
                        </el-form-item>
                        <div>
                            <div class="file" v-for="(item,index) in detail.GKYYDT" :key="index">
                                <div @click="exportFile(item.url,item.name)">
                                <img src="../../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                </div>
                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'GKYYDT')">
                                    <template #reference>
                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="label2">
                    <div>初步设计</div>
                </td>
                <td>
                    <div class="flex fjWrap">
                        <el-form-item>
                            <label class="fright">
                                <i aria-hidden="true" :style="{cursor: isLock ? 'not-allowed' :''}" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                <input :disabled="isLock"  style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput"  multiple="multiple" @change="onSubmit($event,'CBSJZXX')" />
                            </label>
                        </el-form-item>
                        <div>
                            <div class="file" v-for="(item,index) in detail.CBSJZXX" :key="index">
                                <div @click="exportFile(item.url,item.name)">
                                <img src="../../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                </div>
                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'CBSJZXX')">
                                    <template #reference>
                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="flex fjWrap">
                        <el-form-item>
                            <label class="fright">
                                <i aria-hidden="true" :style="{cursor: isLock ? 'not-allowed' :''}" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                <input :disabled="isLock"  style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple"  @change="onSubmit($event,'CBSJYDT')" />
                            </label>
                        </el-form-item>
                        <div>
                            <div class="file" v-for="(item,index) in detail.CBSJYDT" :key="index">
                                <div @click="exportFile(item.url,item.name)">
                                <img src="../../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                </div>
                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'CBSJYDT')">
                                    <template #reference>
                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="label2">
                    <div>施工图设计</div>
                </td>
                <td>
                    <div class="flex fjWrap">
                        <el-form-item>
                            <label class="fright">
                                <i aria-hidden="true" :style="{cursor: isLock ? 'not-allowed' :''}" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                <input :disabled="isLock"  style="width: 72px" type="file" v-show="false" name="file" ref="file" id="fileInput" multiple="multiple"  @change="onSubmit($event,'SGTSJZXX')" />
                            </label>
                        </el-form-item>
                        <div>
                            <div class="file" v-for="(item,index) in detail.SGTSJZXX" :key="index">
                                <div @click="exportFile(item.url,item.name)">
                                <img src="../../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                </div>
                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'SGTSJZXX')">
                                    <template #reference>
                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="flex fjWrap">
                        <el-form-item>
                            <label class="fright">
                                <i aria-hidden="true" :style="{cursor: isLock ? 'not-allowed' :''}" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                <input :disabled="isLock"  style="width: 72px" type="file" v-show="false" name="file" ref="file" multiple="multiple" id="fileInput" @change="onSubmit($event,'SGTSJYDT')" />
                            </label>
                        </el-form-item>
                        <div>
                            <div class="file" v-for="(item,index) in detail.SGTSJYDT" :key="index">
                                <div @click="exportFile(item.url,item.name)">
                                <img src="../../../assets/images/ic-fj.png" alt=""><span style="margin-right: 6px;color: #409eff;overflow:hidden;text-overflow: ellipsis;max-width: 200px">{{item.name}} </span>
                                </div>
                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'SGTSJYDT')">
                                    <template #reference>
                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <div class="flex" style="margin: 0px 0 12px">
            <div class="slfj" style="margin-top: 20px">确认函</div>
        </div>
        <table style="text-align:center" >
            <tr>
                <td>
                    <div class="label2"></div>
                </td>
                <td class="label3">
                    是否确认
                </td>
                <td class="label3">
                    <div >PDF电子版文件</div>
                </td>
            </tr>
            <tr>
                <td class="label2">
                    <div >确认函</div>
                </td>
                <td>
                    <el-form-item>
                        <template>
                            <el-checkbox text-color="#666666" v-model="QRHSFQR">已确认</el-checkbox>
                        </template>
                    </el-form-item>
                </td>
                <td>
                    <div class="flex fjWrap">
                        <el-form-item>
                            <label class="fright">
                                <i aria-hidden="true" :style="{cursor: isLock ? 'not-allowed' :''}" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                <input :disabled="isLock"  style="width: 72px" type="file" v-show="false" name="file" multiple="multiple" ref="file" id="fileInput" @change="onSubmit($event,'QRHFJ')" />
                            </label>
                        </el-form-item>
                        <div>
                            <div class="file" v-for="(item,index) in detail.QRHFJ" :key="index">
                                <a target="view_window" :href="baseUrl+item.url+item.name">
                                    <img src="../../../assets/images/ic-fj.png" alt=""><span  style="margin-right: 6px;color: #409eff">{{item.name}} </span>
                                </a>
                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'QRHFJ')">
                                    <template #reference>
                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                    </template>
                                </el-popconfirm>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <span class="dialog-footer">
                <el-button type="primary" @click="saveDetail">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </span>
    </div>

</template>

<script>
    import store from "../../../store";

    export default {
        name: "SLFJ",
        props:{
            SSWSSWGHXMID:{ type: String },
            XMID: { type: String },
            GKWCD:  { type: String },
            CBSJWCD:  { type: String },
            SGTSJWCD:  { type: String },
            SGQZBWCD:  { type: String },
            addStatus:  { type: Boolean },
            isGg: { type: Boolean },
            isLock: { type: Boolean },
        },
        data(){
            return{
                QRHSFQR: false,
                SSWXMID:'',
                qqId:'',
                height: document.body.offsetHeight - 160,
                jslb:'',
                uploadLoading:false,
                baseUrl: "http://82.156.50.94:8002/",
                // baseUrl: "http://192.168.0.109:9991/",
                cardTitle: "",
                detail: {
                    FJ: [{ url: "212121", name: "133231232" }],
                },
                tableData: [],
                xmmc: "",
                pageSize: 30, //每页记录数
                currentPage: 1,
                total: 0,
                activeIndex: 1,
                active: 0,
                chek: [],
                params: {},
                editColorStatus: true,
                sortData: {
                    field: "",
                    sort: "asc",
                },
                dialogTableVisibleMap:false,
                dialogTableVisibleData:{}
            }
        },
        watch:{
            XMID:{
                handler(val){
                    this.qqId = val
                    if (val != ''){
                        this.openDetailsEdit(val)
                    }
                },
                immediate: true
            },
            SSWSSWGHXMID:{
                handler(val){
                    this.SSWXMID = val
                    // if (val != ''){
                    //     this.getDataopenDetailsEdit(val)
                    // }
                },
                immediate: true
            },
        },
        methods:{
            cancel(){
                this.$emit('cancel')
                // this.$parent.$parent.cancel()
            },
            dealSaveFj(data){
                var pfwjString = []
                data.map((item)=>{
                    pfwjString.push(item.url+item.name)
                })
                return pfwjString.join(';')
            },
            saveDetail() {
                var params = JSON.parse(JSON.stringify(this.detail))
                params.GHDZZXX = this.dealSaveFj(params.GHDZZXX)
                params.GHDZYDT = this.dealSaveFj(params.GHDZYDT)
                params.GKYYDT =this.dealSaveFj(params.GKYYDT)
                params.GKYZXX =this.dealSaveFj(params.GKYZXX)
                params.CBSJYDT =this.dealSaveFj(params.CBSJYDT)
                params.CBSJZXX =this.dealSaveFj(params.CBSJZXX)
                params.SGTSJYDT =this.dealSaveFj(params.SGTSJYDT)
                params.QRHFJ =this.dealSaveFj(params.QRHFJ)
                params.SGTSJZXX =this.dealSaveFj(params.SGTSJZXX)
                params.QRHSFQR = this.QRHSFQR ? 1 : 0 // 1 已确认  0 未确认
                params.SSWGHXMID = ''
                if(params.EarlyID == '00000000-0000-0000-0000-000000000000'){
                    params.EarlyID = this.qqId
                    this.http.post('/api/Land_road_early/AddLandRoad',params).then(res=>{
                                if(res.status){
                                    this.$message.success('保存成功')
                                    this.showCard = false
                                    this.search()
                                }else{
                                    this.$message.error(res.message)

                                }
                            })
                }
                else{
                            this.http.post('/api/Land_road_early/UpdateLandRoad',params).then(res=>{
                                if(res.status){
                                    this.showCard = false
                                    this.$message.success('保存成功')
                                    this.search()
                                }else{
                                    this.$message.error(res.message)

                                }
                            })
                }


            },
            deleted(index, type) {
                this.detail[type].splice(index, 1);
            },
            dealFj(data){
                var a = []
                if(data != null && data != undefined && data != ''){
                    data = data.split(';')
                    data.map((subItem)=>{
                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                        a.push(subItem)
                    })
                    return  a
                }else {
                    return  []
                }

                // if (data != null || data != undefined){
                //  return  {url:data.substring(0,data.lastIndexOf("/") + 1),name:data.substring(data.lastIndexOf("/") + 1,data.length)}
                // }else{
                //     return []
                // }
            },
            openDetailsEdit(ID) {
                if(ID){
                    this.http.post('/api/Land_road_early/getItemById?id='+ID,{}).then(res=>{
                        this.detail = res
                        this.detail.GHDZZXX = this.dealFj(this.detail.GHDZZXX)
                        this.detail.GHDZYDT = this.dealFj(this.detail.GHDZYDT)
                        this.detail.GKYYDT = this.dealFj(this.detail.GKYYDT)
                        this.detail.GKYZXX = this.dealFj(this.detail.GKYZXX)
                        this.detail.CBSJYDT = this.dealFj(this.detail.CBSJYDT)
                        this.detail.CBSJZXX = this.dealFj(this.detail.CBSJZXX)
                        this.detail.SGTSJYDT = this.dealFj(this.detail.SGTSJYDT)
                        this.detail.QRHFJ = this.dealFj(this.detail.QRHFJ)
                        this.detail.SGTSJZXX = this.dealFj(this.detail.SGTSJZXX)
                        this.QRHSFQR =  this.detail.QRHSFQR == 1 ? true : false
                    })
                }else{
                    this.detail = {}
                    this.detail.GHDZZXX = []
                    this.detail.GHDZYDT = []
                    this.detail.GKYYDT = []
                    this.detail.GKYZXX = []
                    this.detail.CBSJYDT = []
                    this.detail.CBSJZXX = []
                    this.detail.SGTSJYDT = []
                    this.detail.QRHFJ = []
                    this.detail.SGTSJZXX = []
                }
                this.showCard = true;

            },
            getCaption(obj){
                var index=obj.lastIndexOf(".");
                obj=obj.substring(index+1,obj.length);
                return obj;
            },
            onSubmit(e, type) {
                this.uploadLoading = true;
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
               let hz =  this.getCaption(file[0].name)
                if (type == 'QRHFJ'){
                    if (hz != 'pdf' ){
                        this.$message.warning('请上传pdf格式文件')
                        return false
                    }
                }else{
                    if (hz != 'dxf' && hz != 'dwg'){
                        this.$message.warning('请上传dxf或dwg格式文件')
                        return false
                    }
                }

                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                console.log(this.detail)
                this.http
                    .post("/api/View_land_road/upload", form)
                    .then((res) => {
                        this.uploadLoading = false;
                        arrs.forEach((v) => {
                            this.detail[type].push({ url: res.data, name: v });
                        });
                    });
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            exportFile(url,name) {
                // var a = this.dealFj(url)
                var that = this
                // a.map((item,index)=>{
                //     console.log(item)
                    this.http.post('/api/Land_road/ExportFile?fileName='+url+name,{}).then(res=> {
                        if (!res.status) {
                            this.$message.error(res.message)
                            return false
                        }
                        let path = "/api/View_land_road/DownLoadFile";
                        path = path[0] == "/" ? path.substring(1) : path;
                        var timestamp = new Date().getTime();
                        this.download(
                            that.baseUrl + path + "?path=" + res.data,
                            name
                        )
                    })
                // })

            },
        }
    }
</script>

<style scoped lang="less">
    .fl-jus {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2 {
        width: 145px;
        height: 36px;
        background: #fafafa;
        line-height: 36px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3 {
        width: 478px;
        height: 36px;
        background: #fafafa;
        line-height: 36px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont {
        width: 220px;
        height: 36px;
        text-align: center;
        line-height: 36px;
    }
    body {
        background: #ffffff !important;
    }
    .flex {
        display: flex;
        align-items: center;
    }
    .delete {
        font-size: 16px;
        color: #f56c6c;
    }
    .dialog-footer {
        width: 100%;
        height: 47px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        background: #f8f8f8;
        align-items: center;
        justify-content: center;
    }
    .wrap {
        padding: 17px 19px;
        /deep/.el-form-item {
            margin: 0;
        }
        /deep/.el-input__inner {
            height: 32px;
        }
        /deep/.el-input--medium .el-input__inner {
            height: 32px;
            line-height: 32px;
        }
        table {
            border-collapse: collapse;
        }
        td {
            border: 1px solid #dddddd;
            .cont {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .slfj {
        text-align: center;
        width: 98px;
        height: 32px;
        background: #409eff;
        opacity: 1;
        border-radius: 0px 16px 16px 0px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        line-height: 32px;
        margin-right: 17px;
    }
    .star {
        font-size: 14px;
        font-weight: bold;
        color: #f56c6c;
    }
    .fjWrap {
        padding: 3px 13px;
        align-items: flex-start;
        .fright {
            margin-right: 12px;
        }
    }
    .file {
        margin-right: 10px;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #b3d8ff;
        opacity: 1;
        border-radius: 4px;
        color: #409eff;
        display: flex;
        align-items: center;
        div {
            cursor: pointer;
            max-width: 408px;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 32px;
            line-height: 32px;
            display: flex;align-items: center
        }
    }
    .dialog-footer {
        width: 100%;
        height: 47px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        background: #f8f8f8;
        align-items: center;
        justify-content: center;
    }

</style>
